export class NotificationService {
    constructor() {
        this.handlers = {
            success: [],
            info: [],
            warning: [],
            error: []
        };
    }

    success(message, opts) {
        this.handlers.success.forEach(p => p(message, opts));
    }

    info(message, opts) {
        this.handlers.info.forEach(p => p(message, opts));
    }

    warning(message, opts) {
        this.handlers.warning.forEach(p => p(message, opts));
    }
    
    error(message, opts) {
        this.handlers.error.forEach(p => p(message, opts));
    }

    on(type, handler) {
        this.handlers[type].push(handler);
    }
};
