import { inject } from 'core';

const TutorialCacheKey = 'tutorial-cache';

const Tutorial = {
    id: 'commcentre-initial-release',
    steps: [
        {
            name: 'user-tray',
            title: `What's new?`,
            content: 'You can now access your student email and important alerts from here.',
            target: '.user-tray',
            placement: 'left'
        },
        {
            name: 'urgent-alert',
            title: 'Urgent alert',
            content: `If an alert becomes urgent, we'll let you know here.`,
            target: '.alert',
            placement: 'bottom',
        }
    ]
};

@inject('$q', 'StorageService', 'SessionService', 'StudentEventLogService')
export class TutorialService {
    constructor(promise, storageService, sessionService, studentEventLogService) {
        this.promise = promise;
        this.storageService = storageService;
        this.studentEventLogService = studentEventLogService;
        this.user = sessionService.current();
        this.tutorialIdCache = storageService.get(TutorialCacheKey, []);
    }

    getTutorial(callbackFilter) {
        if (this.tutorialIdCache.some(id => Tutorial.id == id)) 
            return this.promise.when(null);

        return this.studentEventLogService.getStudentEventLog(this.user.curtinId)
            .then((logs) => {
                if (Tutorial.steps.every(step => logs.some(log => log.eventName == this.$getEventName(step)))) {
                    this.$completeTutorial();
                    return null;
                }

                let steps = Tutorial.steps.filter(step => logs.every(log => log.eventName != this.$getEventName(step)));

                if (typeof(callbackFilter) === 'function')
                    steps = callbackFilter(steps);

                return steps.length > 0 ? { id: Tutorial.id, steps: steps } : null;
            });
    }

    endTutorialStep(step) {
        return this.studentEventLogService.saveStudentEventLog(this.user.curtinId, {
            eventName: this.$getEventName(step)
        });
    }

    endTutorial(step) {
        this.endTutorialStep(step).then(() => {
            let expectedLastStep = Tutorial.steps[Tutorial.steps.length - 1];
            if (step.name === expectedLastStep.name) {
                this.$completeTutorial();
            }
        })
    }

    $getEventName(step) {
        return `${Tutorial.id}:${step.name}`;
    }

    $completeTutorial() {
        this.tutorialIdCache.push(Tutorial.id);
        this.storageService.set(TutorialCacheKey, this.tutorialIdCache);
    }
}