import { inject, debounce } from 'core';
import { animate, easeInOutCubic } from 'core-ui/animate';
import { DateWrapper } from 'elsie/utils';

import {
    startOfWeek,
    startOfMonth,
    endOfWeek,
    endOfMonth,
    eachDayOfInterval,
    isSameDay
} from 'date-fns';

@inject('$q', '$timeout', '$document', '$element', '$scope')
class DatePickerController {
    constructor(promise, timeout, document, element, scope) {
        this.promise = promise;
        this.timeout = timeout;
        this.document = document[0];
        this.element = element[0];
        this.scope = scope;

        this.$init();
    }

    $init() {
        const interval = {
            start: startOfWeek(startOfMonth(this.scope.start)),
            end: endOfWeek(endOfMonth(this.scope.end))
        };

        this.days = eachDayOfInterval(interval).map(dayStart => {
            const highlighted = this.scope.shouldHighlight && this.scope.shouldHighlight({ date: dayStart });
            return new Day(dayStart, highlighted);
        });

        this.weeks = buildWeeks(this.days);

        this.scope.$watch('date', (newValue, oldValue) => {
            if (newValue && (!this.current || !isSameDay(newValue, this.current.date)))
                this.setCurrentDate(newValue);
        });

        this.weeksElement = this.element.querySelector('.date-picker__weeks');
        this.$addClockInterval();
        this.$addDomEventHandlers();
        this.setCurrentDate(this.scope.date);
    }

    setCurrentDay(day) {
        this.$setDay(day);
        day && this.$setDate(day.date);
    }

    setCurrentDate(date) {
        const day = this.days.find(d => isSameDay(d.date, date));
        this.$setDay(day);
        this.$setDate(date);
    }

    closeCalendar() {
        this.showCalendar = false;
        this.scrollToCurrentDay();
    }

    scrollToCurrentDay() {
         this.timeout(() => {
            const el = this.element.querySelector('.selected');
            if (el) {
                this.scrollToDay(this.weeksElement, this.element.querySelector('.selected').offsetTop);
            }
         });
    }

    $setDate(date) {
        this.scope.date = date;
        if (this.scope.onDateChanged)
            this.scope.onDateChanged({ date: date });
    }

    $setDay(day) {
        this.current = day;
        this.closeCalendar();
    }

    $addDomEventHandlers() {
        const scrollHandler = debounce(this.buildScrollHandler(this.weeksElement), 150);

        this.weeksElement.addEventListener('scroll', () => {
            if (this.suppressOpen && !this.animatingScroll)
                this.suppressOpen = false;
            else if (!this.suppressOpen && !this.showCalendar)
                this.scope.$apply(() => this.showCalendar = true);
            
            scrollHandler();
        });

        this.document.addEventListener('click', event => {
            if (!this.element.contains(event.target) && this.showCalendar)
                this.scope.$apply(() => this.closeCalendar());
        });
    }

    $addClockInterval() {
        let lastToday = DateWrapper.new();

        const int = setInterval(() => {
            const newToday = DateWrapper.new();
            if (!isSameDay(lastToday, newToday)) {
                this.scope.$apply(() => this.days.forEach(d => {
                    d.isToday = isSameDay(newToday, d.date);
                }));

                lastToday = newToday;
            }
        }, 1000);

        this.scope.$on('$destroy', () => clearInterval(int));
    }

    buildScrollHandler(element) {
        let rowHeight = null;
        return () => {
            if (!rowHeight) {
                const firstRow = element.querySelector('.date-picker__row');
                const rect = firstRow.getBoundingClientRect();
                rowHeight = rect.height;
            }
    
            const start = element.scrollTop;
            this.scrollToDay(element, Math.round(start / rowHeight) * rowHeight);
        }
    }
    
    scrollToDay(element, target) {
        const start = element.scrollTop;
        const difference = target - start;

        // Don't scroll if within a pixel of the target, this solves a problem with being at the bottom of the element
        if (Math.abs(difference) <= 1)
            return;
    
        this.suppressOpen = true;
        this.animatingScroll = true;
            
        let step = -1;
        let animation;
        const animationRenderer = p => {
            if (step >= 0 && Math.abs(element.scrollTop - step) > 2) {
                animation();
            }
            else {
                step = start + (difference * p);
                element.scrollTop = step;
            }
        };
        
        return this.promise((resolve) => {
            animation = animate(animationRenderer, 150, easeInOutCubic, 
                () => {
                    this.animatingScroll = false;
                    resolve();
                });
        });
    }
}

export function DatePickerDirective() {

}

DatePickerDirective.prototype = {
    restrict: 'EA',
    templateUrl: require('./date-picker.html'),
    controller: DatePickerController,
    controllerAs: 'vm',
    scope: {
        start: '=',
        end: '=',
        date: '=',
        view: '=',
        onDateChanged: '&?',
        shouldHighlight: '&?'
    }
}

function buildWeeks(days) {
    const weeks = new Array(Math.ceil(days.length / 7));

    for(let i = 0, j = days.length; i < j; ++i) {
        const weekIdx = Math.floor(i / 7);
        if (!weeks[weekIdx])
            weeks[weekIdx] = [];
        weeks[weekIdx].push(days[i]);
    }

    return weeks;
}

class Day {
    constructor(date, highlighted) {
        this.date = date;
        this.isEvenMonth = date.getMonth() % 2 === 0;
        this.isFirstDay = date.getDate() === 1;
        this.isToday = isSameDay(date, DateWrapper.new());
        this.isHighlighted = highlighted;
    }
}