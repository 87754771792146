import { inject } from 'core';
import { groupBy, DateWrapper } from 'elsie/utils';

const PassFailResultType = 'Pass/Fail';

@inject('$q', '$state', 'SessionService', 'StudentService', 'ViewService')
export class UnitsOverviewView {
    constructor(promise, state, sessionService, studentService, viewService) {
        this.promise = promise;
        this.state = state;
        this.user = sessionService.current();
        this.studentService = studentService;
        this.now = DateWrapper.new();

        this.targetYear = this.state.params.year;
        this.targetStudyPeriod = this.state.params.studyPeriod;
        
        this.$viewPromise = viewService.wrap(this.$init());
    }

    $init() {
        const promises = [
            this.studentService.getActiveStudentUnits(this.user.curtinId),
            this.now.getFullYear()
        ];

        return this.promise.all(promises).then(([units, relevantYear]) => {
            const years = groupByYear(units);

            this.targetYear = this.targetYear || relevantYear;
            const sprds = years.find(y => y.year == this.targetYear);

            this.targetStudyPeriod = this.targetStudyPeriod || (sprds && sprds[0].studyPeriodCode);
            const selectedStudyPeriod = sprds && sprds.find(sprd => sprd.studyPeriodCode == this.targetStudyPeriod)

            this.studyPeriods = sprds || [];
            this.setCurrentStudyPeriod(selectedStudyPeriod);

            // To prevent heaps of state looping, only show 'other' years when 
            // on the default year (the relevant one).
            this.otherYears = this.targetYear == relevantYear 
                ? years.filter(sprds => sprds.year != this.targetYear) 
                : null;
        });
    }

    setCurrentStudyPeriod(units) {
        this.currentStudyPeriod = units;
        
        if (units) {
            this.targetStudyPeriod = units.studyPeriodCode;
            this.$updateUrl();
        }
    }

    hasGrade(unit) {
        return unit.grade && unit.grade.length;
    }

    getUnitResultText(unit) {
        if (unit.resultType === PassFailResultType)
            return null;

        return unit.statusCode === unit.grade.toUpperCase()
            ? unit.statusCode
            : `${unit.statusCode} (${unit.grade})`;
    }

    getUnitMarkText(unit) {
        return unit.resultType === PassFailResultType
            ? unit.statusCode
            : unit.mark;
    }

    $updateUrl() {
        return this.state.transitionTo(
            'units.overview', 
            { year: this.targetYear, studyPeriod: this.targetStudyPeriod }, 
            { location: 'replace', reload: false }
        );
    }
}

// If we ever support multiple 'year' views
// this can be used to group at a level up from studyPeriod
function groupByYear(units) {
    return groupBy(units, u => u.year).map(year => {
        const sprds = groupByStudyPeriod(year);
        sprds.year = year[0].year;
        return sprds;
    });
}

function groupByStudyPeriod(units) {
    return groupBy(units, u => u.studyPeriodCode).map(sprd => {
        const faculties = groupByFaculty(sprd);
        faculties.studyPeriod = sprd[0].studyPeriod;
        faculties.studyPeriodCode = sprd[0].studyPeriodCode;
        return faculties;
    });
}

function groupByFaculty(units) {
    return groupBy(units, u => u.faculty, 'name');
}

