export function ExceptionHandlerProvider() {
    const options = {
        rateLimit: 5, // default to 5 errors per second
        filters: [
            // HttpErrors that have valid http status codes < 500
            e => e.name === 'HttpError' && e.status >= 100 && e.status < 500,
            // transition superseded errors from the ui-router
            e => e.message.indexOf('transition superseded') >= 0
        ]
    };

    this.addExceptionFilter = (fn) => {
        if (typeof(fn) !== 'function')
            throw new TypeError('"fn" must be a function');
        options.filters.push(fn);
    };

    this.setRateLimit = (limit) => {
        if (typeof(limit) !== 'number')
            throw new TypeError('"limit" must be a number');
        options.rateLimit = limit;
    };

    this.$get = [ '$log', '$injector', (logger, injector) => {
        return createExceptionHandler(logger, injector, options);
    }];
}

function createExceptionHandler(logger, injector, options) {
    const { rateLimit, filters } = options;

    let counter = 0,
        rootScope;

    if (rateLimit) {
        // Reset the counter every second to reset the rate limit
        setInterval(() => counter = 0, 1000);
    }

    return function(exception, cause) {
        // Wrap in an error if not already
        const error = exception instanceof Error 
            ? exception 
            : wrapError(exception);
        
        // Check that the error was not filtered
        if (!filters.some(fn => fn(error))) {
            logger.error(error, cause);

            if (!rateLimit || ++counter <= rateLimit) {
                const scope = rootScope || (rootScope = injector.get('$rootScope'));
                scope.$emit('$exception', error, exception, cause);
            }
        }
    }
}

function wrapError(original) {
    const message = typeof(original) !== 'string' 
        ? JSON.stringify(original)
        : original; 

    return new Error(message);
}
