AddApplicationInsightsTracking.$inject = [ '$rootScope', '$location', '$transitions', '$window', 'SessionService', 'AppInsights' ];

export function AddApplicationInsightsTracking(rootScope, location, transitions, window, sessionService, appInsights) {
    if (appInsights.isEnabled()) {
        appInsights.initialise();

        // Add transition logging
        transitions.onStart({}, transition => {
            transition.$$started = Date.now();
        });

        transitions.onSuccess({}, transition => {
            const duration = transition.$$started ? (Date.now() - transition.$$started) : 0;
            appInsights.trackPageView(transition.to().name, location.path(), null, null, duration);
        });

        // Add exception logging
        rootScope.$on('$exception', (event, err, ex, cause) => {
            // TODO: Look at pull severity levels into a file so we can `import` them
            // as an enum and stop using magic numbers
            appInsights.trackError(err, 'ExceptionHandler', { Cause: cause });
        });

        // Hook into authentication events
        const user = sessionService.current();

        if (user !== null)
            appInsights.setUser(user.curtinId);

        rootScope.$on('$userSessionStarted', (event, user) => {
            appInsights.setUser(user.curtinId);
        });

        rootScope.$on('$userSessionEnded', event => {
            appInsights.clearUser();
        });

        // Add browser navigation event logging
        window.addEventListener('popstate', event => {
            appInsights.trackEvent('ClientBrowserNavigation');
        });
    }
}