
import { inject } from 'core';
import { transformDateProps } from 'elsie/utils';
import config from 'config';

const CacheConfig = config.cache.studentEventService;

@inject('WebApiService', 'CacheFactory')
export class StudentEventService {
    constructor(webApi, cacheFactory) {
        this.webApi = webApi;
        this.cache = cacheFactory.createApiCache(CacheConfig);
    }

    getStudentEvents(studentId) {
        return this.cache.get(getBaseUri(studentId))
            .then(events => transformDateProps(events, [ 'startDateTime', 'endDateTime' ]))
    }

    getStudentEventById(studentId, eventId) {
        return this.getStudentEvents(studentId)
            .then(events => events && events.find(e => e.id == eventId));
    }

    saveStudentEvent(studentId, event) {
        const baseUri = getBaseUri(studentId);

        const promise = event.id != null
            ? this.webApi.put(`${baseUri}/${encodeURIComponent(event.id)}`, event)
            : this.webApi.post(baseUri, event);

        return promise.then(res => {
            if (event.id == null)
                event.id = res.data;
            
            // Invalidate the cache
            this.cache.remove(baseUri);
        });
    }

    removeStudentEvent(studentId, eventId) {
        const baseUri = getBaseUri(studentId);

        return this.webApi.delete(`${baseUri}/${encodeURIComponent(eventId)}`).then(res => {
            res.status === 200 && this.cache.remove(baseUri);
            return res;
        });
    }
}

function getBaseUri(studentId) {
    return `/students/${encodeURIComponent(studentId)}/student-events`;
}