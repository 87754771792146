export function TimelineDirective(interval) {
    this.interval = interval;
};

TimelineDirective.prototype = {
    restrict: 'A',
    replace: true,
    template: `
        <div class="timeline">
            <div id="currentTime" style="top:{{top}}%;">
                <span></span>
            </div>
        </div>
    `,
    scope: { 
        startTime: '=',
        endTime: '='
    },
    dependencies: [ '$interval' ],
    link: function(scope, $element, attrs) {
        let timeRange = scope.endTime - scope.startTime + 1;
        let updateInterval = this.interval(updateTime, 1000 * 60);
        
        updateTime();

        function updateTime() {
            let now = new Date();
            scope.top = (((now.getHours() - scope.startTime) + (now.getMinutes() / 60)) / timeRange) * 100;
        };

        $element.on('$destroy', () => {
            this.interval.cancel(updateInterval);
        });
    }
};