export function DateWrapper() { }


import { isStorageSupported } from 'core';

const useStorage = isStorageSupported(window.localStorage);
const storageKey = 'DateWrapper.$$mocked';

DateWrapper.now = () => DateWrapper.$$mocked ? DateWrapper.$$mocked.getTime() : Date.now();
DateWrapper.new = () => DateWrapper.$$mocked || new Date();

DateWrapper.set = function(mockedDate) {
    DateWrapper.$$mocked = mockedDate;
    useStorage && window.localStorage.setItem(storageKey, mockedDate ? mockedDate.toISOString() : '');
};

DateWrapper.reset = function() {
    DateWrapper.$$mocked = null;
    window.localStorage.setItem(storageKey, '');
}

// Initialise the mocked date from storage if it exists
const existing = useStorage ? window.localStorage.getItem(storageKey) : '';
if (existing)
    DateWrapper.$$mocked = new Date(existing);

