import { inject } from 'core';
import { transformDateProps } from 'elsie/utils';
import config from 'config';

@inject('CacheFactory')
export class LibraryService {
    constructor(cacheFactory) {
        const cacheConfig = config.cache.libraryService;
        this.cache = cacheFactory.createApiCache(cacheConfig);
    }

    getStudentLoans(studentId) {
        return this.cache.get(`/students/${encodeURIComponent(studentId)}/library-loans`)
            .then(loans => transformDateProps(loans, [ 'dueDateTime' ]));
    }

    getStudentRequests(studentId) {
        return this.cache.get(`/students/${encodeURIComponent(studentId)}/library-requests`)
            .then(requests => transformDateProps(requests, [ 'requestDate' ]));
    }
}