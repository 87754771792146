import { inject } from 'core';
import { groupBy, getFacultyIdentifier, watchOnce } from 'elsie/utils';

@inject('$scope')
export class AssessmentsOverviewUnitView {
    constructor(scope) {
        watchOnce(scope, 'assessments', assessments => {
            this.units = groupBy(assessments, a => a.unitAvailabilityId);

            // Attach the unit to each group, map a faculty id
            this.units.forEach(u => {
                u.unit = u[0].unit;
                u.facultyId = getFacultyIdentifier(u.unit.faculty);
            });
        });
    }
}