import { createFwlink } from 'elsie/utils';

const RefreshInterval = 10 * 60 * 1000; // 10 minutes

export function UserTrayDirective(interval, sessionService, communicationsService) {
    this.interval = interval;
    this.sessionService = sessionService;
    this.communicationsService = communicationsService;
 };

UserTrayDirective.prototype = {
    restrict: 'A',
    replace: true,
    templateUrl: require('./user-tray.html'),
    dependencies: [ '$interval', 'SessionService', 'CommunicationsService' ],
    link: function(scope) { 
        const user = this.sessionService.current();
        scope.email = createFwlink(user, 'inbox');

        const update = () => {
            scope.error = false;
            return this.communicationsService.getStudentNotificationsOverview(user.curtinId, true)
                .then(overview => {
                    scope.notificationCount = overview.notificationCount;
                    scope.unreadEmailsCount = overview.unreadEmailsCount;
                })
                .catch(() => scope.error = true); // TODO: Error handling? Don't want to display an error when service drops out.
        };

        const promise = this.interval(update, RefreshInterval);
        scope.$on('$destroy', () => this.interval.cancel(promise));

        // Kick off an initial update
        update();
    }
};