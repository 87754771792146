import hopscotch from 'hopscotch';

export function TutorialDirective(tutorialService) {
    this.tutorialService = tutorialService;
};

TutorialDirective.prototype = {
    restrict: 'A',
    template: `<div ng-show="isShowing" class="tutorial-showing"></div>`,
    dependencies: [ 'TutorialService' ],
    scope: {
        tutorial: '='
    },
    link: function(scope) {
        let removeHighlight;
        let tutorialService = this.tutorialService;

        startTutorial(scope.tutorial);
        scope.isShowing = true;

        function highlight(step) {
            let element = angular.element(document.querySelector(step.target));
            element.addClass('tutorial-highlight');
            return () => {
                element.removeClass('tutorial-highlight');
                return step;
            };
        }

        function startTutorial(tutorial) {
            tutorial.steps.forEach((ele, idx) => {
                ele.onShow = () => {
                    removeHighlight = highlight(tutorial.steps[idx]);
                }
            });

            hopscotch.configure({
                showCloseButton: false,
                onNext: () => {
                    let step = removeHighlight();
                    tutorialService.endTutorialStep(step);
                },
                onEnd: () => {
                    scope.isShowing = false;
                    let step = removeHighlight();
                    tutorialService.endTutorial(step);
                },
                i18n: {
                    doneBtn: 'Got it!'
                }
            });

            hopscotch.startTour(tutorial, 0);
        }
    }
};
