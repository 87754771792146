import { inject } from 'core';
import { FriendlyError } from 'core-ui';
import { formatDistance, formatUnitShort, getActivityType, toString } from 'elsie/utils';

@inject('$q', '$state','SessionService', 'StudentService', 'ViewService')
export class PlannerActivityView {
    constructor(promise, state, sessionService, studentService, viewService) {
        this.state = state;
        this.user = sessionService.current();
        this.studentService = studentService;
        
        this.$viewPromise = viewService.wrap(this.$init());
    }

    $init() {
        return this.$getStudyActivity().then(activity => {
            if (!activity)
                throw new FriendlyError('Unknown study activity.');
            
            this.activity = activity;
            this.entryType = getActivityType(activity);
        });
    }

    $getStudyActivity() {
        const { activity, activityId } = this.state.params;

        if (activity)
            return this.promise.when(activity);

        return this.studentService.getStudentStudyActivities(this.user.curtinId)
            .then(activities => activities && activities.find(a => a.activityId === activityId));
    }

    getDuration() {
        const formatted = formatDistance(this.activity.endDateTime, this.activity.startDateTime, formatUnitShort);
        return formatted.length > 0 ? `(${formatted.join(' ')})` : '';
    }

    formatBuildingText(arrString) {
        return arrString.filter(s=>s).join('.');
    }
}