import { inject } from 'core';
import { transformDateProps } from 'elsie/utils';
import config from 'config';

@inject('CacheFactory')
export class UnitService {
    constructor(cacheFactory) {
        const cacheConfig = config.cache.unitService;
        this.cache = cacheFactory.createApiCache(cacheConfig);
    }
    
    getAvailabilityAssessments(availabilityId) {
        return this.cache.get(`/availabilities/${encodeURIComponent(availabilityId)}/assessments`)
            .then(assessments => transformDateProps(assessments, [ 'dueDateTime' ]));
    }

    getAvailabilityOutline(availabilityId, attendanceModeCode) {
        return this.cache.get(`/availabilities/${encodeURIComponent(availabilityId)}/outline?attendanceModeCode=${encodeURIComponent(attendanceModeCode)}`).then(outline => {
            if (outline && !outline.pdfUrl)
                outline.pdfUrl = getUnitOutlineUrl(outline.unitNumber, availabilityId);
            return outline;
        });
    }

    getAvailabilityAnnouncements(availabilityId) {
        return this.cache.get(`/availabilities/${encodeURIComponent(availabilityId)}/announcements`)
            .then(announcements => transformDateProps(announcements, [ 'publicationStartDateTime', 'publicationEndDateTime', 'dateTimeLastModified' ]));
    }
}

function getUnitOutlineUrl(spkNumber, availabilityId) {
    return `${config.urls.unitOutline}/${encodeURIComponent(spkNumber)}/${encodeURIComponent(availabilityId)}.pdf`;
}
