import angular from 'angular';
import router from '@uirouter/angularjs';
import dynamicModel from 'ng-dynamic-model';
import coreLib from 'core/lib';
import coreUiLib from 'core-ui/lib';

import 'content/css/auth.scss';

import { SessionService } from './services/session';
import { RouteConfig } from './config/routes';
import { AuthConfigProvider } from './config/auth-config-provider';
import { LoginView } from './views/login';
import { LogoutView } from './views/logout';
import { AddTransitionAuthentication } from './runners/add-transition-authentication';
import { AddWebApiMiddleware } from './runners/add-webapi-middleware';

let lib = angular.module('auth.lib', [ router, coreLib, coreUiLib, dynamicModel ])
    .config(RouteConfig)

    // runners
    .run(AddTransitionAuthentication)
    .run(AddWebApiMiddleware)

    .provider('AuthConfig', AuthConfigProvider)
    .service('SessionService', SessionService)

    // views/controllers
    .controller('LoginView', LoginView)
    .controller('LogoutView', LogoutView)
    
export default lib.name;