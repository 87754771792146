import { inject } from 'core';
import { DateWrapper } from 'elsie/utils';
import { 
    areIntervalsOverlapping,
    differenceInMinutes,
    startOfDay,
    endOfDay,
    addDays,
    subDays,
    addMinutes
} from 'date-fns';
import { isSameDay } from 'date-fns/esm';

const defaultEntryDurationMinutes = 60;
const minEntryDurationMinutes = 15;

export function TablePlannerDirective() { }

@inject('$scope', '$q', '$state', '$element', '$timeout')
class TablePlannerController {
    constructor(scope, promise, state, element, timeout){
        this.promise = promise;
        this.timeout = timeout;
        this.state = state;
        this.element = element[0];
        this.scope = scope;

        this.init();
    }

    init() {
        this.timeList = this.getTimeList();
        this.startTime = 8;
        this.endTime = 20;

        this.$addClockInterval();
        this.scrollToDayTop();

        const dayCache = {};

        this.scope.$watch('selectedDate', (newValue) => {
            if (newValue) {
                if (!dayCache.hasOwnProperty(newValue))
                    dayCache[newValue] = computeDayModel(newValue, this.scope.entries);
                this.selected = dayCache[newValue];
                this.isTodaySelected = isSameDay(this.scope.selectedDate, DateWrapper.new());
                this.scrollToDayTop();
            }
        });
    }

    scrollToDayTop() {
        this.timeout(() => {
            const startOfDay = this.element.querySelector('.time-day');
            const firstEvent = this.element.querySelector('.day-view-container .event');

            const top = firstEvent && firstEvent.offsetTop < startOfDay.offsetTop 
                ? firstEvent.offsetTop 
                : startOfDay.offsetTop

            this.element.querySelector('.day-view-container').scrollTop = top
        });
    }

    selectNextDay() {
        this.scope.selectedDate = addDays(this.scope.selectedDate, 1);
    }

    selectPreviousDay() {
        this.scope.selectedDate = subDays(this.scope.selectedDate, 1);
    }

    getTimeList() {
        const times = new Array(24);
        for(var i = 0, j = times.length; i < j; ++i)
            times[i] = i;
        return times;
    }

    getEntryUrl(entry) {
        switch(entry.type) {
            case 'assessment': return this.state.href('assessments.details', { assessmentId: entry.ref.id });
            case 'student-event': return this.state.href('my-events.details', { id: entry.ref.id });
            case 'exam': return this.state.href('exams.list', { eventId: entry.ref.event.id, examId: entry.ref.exam.id });
            default: return this.state.href('planner.activity', { activityId: entry.ref.activityId });
        }
    }

    getSegmentStyle(segment) {
        return {
            'top': segment.top * 100 + '%',
            'height': segment.height * 100 + '%',
            'left': segment.left * 100 + '%',
            'width': segment.width * 100 + '%'
        };
    }

    $addClockInterval() {
        let lastToday = DateWrapper.new();

        const int = setInterval(() => {
            const newToday = DateWrapper.new();
            if (!isSameDay(lastToday, newToday)) {
                this.scope.$apply(() =>
                    this.isTodaySelected = isSameDay(this.scope.selectedDate, newToday)
                );
                lastToday = newToday;
            }
        }, 1000);

        this.scope.$on('$destroy', () => clearInterval(int));
    }    
}

TablePlannerDirective.prototype = {
    restrict: 'A',
    replace: true,
    templateUrl: require('./table-planner.html'),
    controller: TablePlannerController,
    controllerAs: 'vm',
    scope: { 
        entries: '=',
        selectedDate: '='
    },
};

function computeDayModel(date, entries) {
    const levels = [];
    const allDayEntries = [];
    const dayInterval = { start: startOfDay(date), end: endOfDay(date) };
    
    entries.forEach(entry => {
        // Ensure the entry has some overlap with the current day
        if (!areIntervalsOverlapping(dayInterval, entry))
            return; 

        if (entry.isAllDay)
            allDayEntries.push(entry);
        else {
            let normalisedEndTime = entry.end;
            const timeDifferenceInMinutes = differenceInMinutes(entry.end, entry.start);

            if (timeDifferenceInMinutes <= 0)
                normalisedEndTime = addMinutes(entry.start, defaultEntryDurationMinutes);
            else if (timeDifferenceInMinutes < minEntryDurationMinutes)
                normalisedEndTime = addMinutes(entry.start, minEntryDurationMinutes);

            const meta = {
                entry: entry,
                // Handle dates that span multiple days by clamping to the current day
                start: entry.start > dayInterval.start ? entry.start : dayInterval.start,
                end: normalisedEndTime < dayInterval.end ? normalisedEndTime : dayInterval.end,
            };
            meta.duration = differenceInMinutes(meta.end, meta.start);

            let level = levels.findIndex(items => {
                // Find the first level that has no collision with the current object
                return items.every(o => !areIntervalsOverlapping(o, meta));
            });

            if (level < 0) {
                level = levels.length;
                levels.push([]);
            }

            levels[level].push(meta);
        }
    });

    // Flatten out the levels and compute the segment bounds
    const segments = levels.reduce((segments, items, level) => {
        items.forEach(meta => {
            segments.push(Object.assign(computeBounds(meta, level, levels, dayInterval.start), {
                entry: meta.entry,
                duration: meta.duration
            }));
        });
        
        return segments;
    }, []);

    return new DayModel(segments, allDayEntries);
}

function computeBounds(meta, level, allLevels, dayStart) {
    const levelWidth = 1 / allLevels.length;
    const minutesInDay =  1440; //assumes we are displaying 24hours

    return {
        left: level * levelWidth,
        width: (findNextLevel(meta, allLevels, level + 1) - level) * levelWidth,
        top: (differenceInMinutes(meta.start, dayStart) / minutesInDay),
        height: (differenceInMinutes(meta.end, meta.start) / minutesInDay)
    };
}

function findNextLevel(meta, levels, start) {
    for(let end = levels.length; start < end; ++start) {
        if (levels[start].some(o => areIntervalsOverlapping(o, meta)))
            return start;
    }

    return levels.length;
}

class DayModel {
    constructor(segments, allDayEntries) {
        this.hasEntries = (segments && segments.length) || (allDayEntries && allDayEntries.length);
        this.segments = segments;
        this.allDayEntries = allDayEntries;
        this.numberOfAssessments = segments && segments.filter(s => s.entry.type === 'assessment').length;
    }
}