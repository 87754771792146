import { inject } from 'core';
import { getFacultyIdentifier, formatDueDate } from 'elsie/utils';

@inject('$q', '$stateParams', 'SessionService', 'StudentService', 'UnitService', 'ViewService')
export class AssessmentsDetailsView {
    constructor(promise, stateParams, sessionService, studentService, unitService, viewService) {
        this.user = sessionService.current();
        this.promise = promise;
        this.studentService = studentService;
        this.stateParams = stateParams;
        this.unitService = unitService;
        this.formatDueDate = formatDueDate;

        this.$viewPromise = viewService.wrap(this.$init());
    }

    $init() {
        return this.$getAssessment().then(assessment => {
            this.assessment = assessment;
            this.loadingOutline = true;
            
            this.unitService.getAvailabilityOutline(assessment.unit.availabilityId, assessment.unit.attendanceModeCode)
                .then(unitOutline => this.unitOutline = unitOutline)
                .finally(() => this.loadingOutline = false);
        });
    }

    $getAssessment() {
        if (this.stateParams.assessment)
            return this.promise.when(this.stateParams.assessment);

        const assessmentId = this.stateParams.assessmentId;
        return this.studentService.getStudentAssessments(this.user.curtinId)
            .then(assessments => assessments.find(a => a.id == assessmentId));
    }

    getFacultyClass() {
        return `faculty-${getFacultyIdentifier(this.assessment.unit.faculty)}1`;
    }
}