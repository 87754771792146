import 'content/css/_view-loader.scss';

export function ViewLoaderDirective(promise) { 
    this.promise = promise;
}

ViewLoaderDirective.prototype = {
    restrict: 'ECA',
    priority: 0,
    dependencies: [ '$q' ],
    link: function(scope, $element) {
        const data = $element.data('$uiView');

        // Wait until $view.sync() has been called (when the config is applied)
        if (data && data.$cfg) {
            const controller = $element.controller();
            
            return this.promise.when(controller && controller.$viewPromise)
                .finally(() => $element.addClass('loaded'));
        }
    } 
}