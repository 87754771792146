import { inject } from 'core';

@inject('$state', '$window', '$location', 'SessionService', 'StorageService', 'AuthConfig', 'ModelBuilder')
export class LoginView {
    constructor(state, window, location, sessionService, storage, authConfig, modelBuilder) {
        this.state = state;
        this.window = window;
        this.location = location;
        this.sessionService = sessionService;     
        this.storage = storage;
        this.authConfig = authConfig;   
        this.model = modelBuilder.build();

        this.logout = this.state.params.logout;
        this.expired = this.state.params.expired;
        this.returnTo = this.state.params.returnTo 
            || (this.storage.has('returnTo') ? this.storage.unset('returnTo') : null); 
    }

    login() {
        this.submitting = true;

        const promise = this.model.validate()
            .then(valid => valid && this.sessionService.login(this.model.getState()));

        promise.then(res => {
            this.errors = res ? res.errors : null;
            if (res && res.status === 201) 
                return this.redirect(res.data);
            
            this.logout = false;
            this.submitting = false;
        }).catch(() => this.submitting = false);
    }

    redirect(user) {
        if (this.returnTo) {
            if (this.authConfig.reloadOnLogin)
                this.window.location.href = this.state.href(this.returnTo.state, this.returnTo.params, { absolute: true, inherit: false });
            else
                this.state.go(this.returnTo.state, this.returnTo.params, { location: 'replace' });
        }
        else {
            var returnUrl = this.state.params.ReturnUrl;
            var defaultReturnUrl = this.authConfig.defaultReturnUrl;

            if (!returnUrl || returnUrl[0] !== '/' || returnUrl[1] === '/')
                returnUrl = typeof(defaultReturnUrl) === 'function' ? defaultReturnUrl(user) : defaultReturnUrl;
            this.window.location.href = returnUrl;
        }
    }
}