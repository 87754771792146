
const DefaultErrorMessage = 'An error has occurred. If the error persists, report the problem in the Support tile.';
const TemplateLoadError = /^\[\$compile:tpload\]/;

AddExceptionNotifications.$inject = ['$rootScope', 'NotificationService'];

export function AddExceptionNotifications(rootScope, notificationService) {
    rootScope.$on('$exception',  (event, err) => {
        const message = getErrorMessage(err);
        message && notificationService.error(message)
    });
}

function getErrorMessage(err) {
    if (err instanceof Error) {
        if (err.name === 'FriendlyError') 
            return err.message;
        
        // Check for HttpError, as well as wrapped errors from $templateRequest
        if ((err.name === 'HttpError' && err.status <= 0) || TemplateLoadError.test(err.message))
            return 'Unable to contact the elsie servers, please check your internet connection.';
    }

    return DefaultErrorMessage;
}