export function isStorageSupported(storage) {
    if (!storage)
        return false;
               
    try {
        // This additional test is due to Private Mode in  Safari in 
        // iOS 10 *technically* having sessionStorage, but having a quota
        // of 0, making any `setItem` call fail.
        const testKey = '__storage_test';
        storage.setItem(testKey, testKey);
        storage.removeItem(testKey);
        return true;
    }
    catch(ex) {
        return false;
    }
}