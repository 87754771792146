import { inject } from 'core';

export function ProgressBarDirective() {}

ProgressBarDirective.prototype = {
    selector: "progressBar",
    restrict: 'AE',
    controllerAs: 'vm',
    template: `<div class="progressBar" ng-style="{width: vm.progress + '%', visibility: vm.show ? 'visible' : 'hidden'}"></div>`,
    controller: ['$scope', '$timeout', '$document', function(scope, timeout, document){
        this.started = 0;
        this.completed = 0;
        this.progress = 0;
        this.show = false;
        this.visibilityTimer = null;
        
        this.scope = scope;
        this.timeout = timeout;
        document = document[0];

        document.addEventListener("startProgress", () => this.timeout(this.start));
        document.addEventListener("completeProgress", () => this.timeout(this.complete, 150));

        this.getPending = getPending;
        this.start = start.bind(this);
        this.complete = complete.bind(this);

        function getPending() {
            return this.started - this.completed;
        }

        function start() {
            //Clear any pending visibility timer from very recent completions
            if (this.visibilityTimer) {
                this.timeout.cancel(this.visibilityTimer);
                this.visibilityTimer = null;
            }
            //Reset
            if (this.getPending() <= 0) {
                this.started = 0;
                this.completed = 0;
                this.progress = 0;
            }

            this.started++;
            this.show = true;

            this.progress = Math.max(10, this.progress);
        }

        function complete() {
            let increase = 1 / this.getPending();
            this.progress = Math.max(10, this.progress + ((100 - this.progress) * increase));
            this.completed++;

            //Add margin for rounding errors
            if (this.progress >= 99) {
                this.visibilityTimer = this.timeout(() => {
                    this.show = false;
                    this.progress = 0;
                }, 1500);
            }
        }
    }]
};

@inject('$document')
export class ProgressService {
    constructor(document) {
        this.document = document[0];
        this.startEvent = new CustomEvent("startProgress");
        this.completeEvent = new CustomEvent("completeProgress");
    }

    start() {
        this.document.dispatchEvent(this.startEvent);
    }

    complete() {
        this.document.dispatchEvent(this.completeEvent);
    }
}

ProgressHttpInterceptor.$inject = ['$httpProvider'];
export function ProgressHttpInterceptor(httpProvider) {
    httpProvider.interceptors.push(["$q", "ProgressService", 
        (promise, progressService) => 
            new ProgressHttpInterceptorImp(promise, progressService)
    ]);
}

class ProgressHttpInterceptorImp {
    constructor(promise, progressService) {
        this.promise = promise;
        this.progressService = progressService;
        this.request = this.request.bind(this);
        this.response = this.response.bind(this);
        this.responseError = this.responseError.bind(this);
    }

    request(config) {
        if (!config.silent)
            this.progressService.start();

        return config;
    }

    response(response) {
        if (!response.config.silent)
            this.progressService.complete();

        return response;
    }

    responseError(response) {
        if (!response || !response.config || !response.config.silent) {
            this.progressService.complete();
        }

        return this.promise.reject(response);
    }
}