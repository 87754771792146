HttpConfig.$inject = [ '$httpProvider' ];

export function HttpConfig(httpProvider) {
    // Wrap all $http errors in an actual Error object
    //  this allows the error context to be accessible in 'unhandled rejections'
    //  as Angularjs throws away the errors in unhandled rejections unless they are
    //  actually an instanceof Error
    const interceptor = ['$q', q => ({ responseError: err => q.reject(wrapError(err)) })];
    httpProvider.interceptors.push(interceptor);
}

function wrapError(err) {
    if (err instanceof Error)
        return err;

    const error = new Error(`${err.config.method} "${err.config.url}" ${err.status} (${err.xhrStatus})`);
    Object.assign(error, err);
    error.name = 'HttpError';

    return error;
}