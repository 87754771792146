import { DateWrapper } from 'elsie/utils';

export class MockingView {
    constructor() {
        this.date = DateWrapper.new(); 
    }

    setDate() {
        DateWrapper.set(this.date);
    }

    resetDate() {
        DateWrapper.reset();
        this.date = DateWrapper.new(); 
    }

    throwError() {
        throw 'Error from error mocking tool';
    }
}