import { inject } from 'core';

@inject('SupportService', 'ViewService')
export class SupportContactsView {
    constructor(supportService, viewService) {

        this.$viewPromise = viewService.wrap(supportService.getFacultyContacts()
            .then((contacts) => {
                this.facultyContacts = contacts;
            }));
    }
}