import { inject } from 'core';

const FacultyContacts = [
    {
        faculty: 'Faculty of Business and Law',
        email: 'business@cbs.curtin.edu.au'
    },
    {
        faculty: 'Health Sciences',
        email: 'currentstudents@health.curtin.edu.au'
    },
    {
        faculty: 'Humanities',
        email: 'humanities@curtin.edu.au',
    },
    {
        faculty: 'Science and Engineering',
        email: 'ssse@curtin.edu.au'
    },
    {
        faculty: 'Centre for Aboriginal Studies',
        phone: '+61 8 9266 7091',
        email: 'cas.enquiries@curtin.edu.au',
        location: 'Building 211, Level 1'
    }
];

@inject('$q')
export class SupportService {
    constructor(promise) {
        this.promise = promise;
    }

    getFacultyContacts() {
        return this.promise.when(FacultyContacts);
    }
}