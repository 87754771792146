import { inject } from 'core';
import { getFacultyIdentifier, groupBy } from 'elsie/utils';

@inject('$q', 'SessionService', 'StudentService', 'ViewService')
export class ProgressOverviewView {
    constructor(promise, sessionService, studentService, viewService) {
        this.promise = promise;
        this.user = sessionService.current();
        this.studentService = studentService;

        this.courseStatusIcons = {
            'C': 'status-conditional',
            'GS': 'status-good',
            'T': 'status-terminated'
        };

        this.$viewPromise = viewService.wrap(this.$init());
    }

    $init() {
        const promises = [
            this.getStudentCourseProgression(this.user.curtinId),
            this.studentService.getStudentSanctions(this.user.curtinId)
        ];

        return this.promise.all(promises).then(([courses, sanctions]) => {
            this.sanctions = sanctions;

            if (courses) {
                this.courses = courses;
                this.faculties = groupBy(courses, c => getFacultyIdentifier(c.faculty));
            }

            if (!this.shouldWithholdResult)
                this.shouldWithholdResult = (sanctions && sanctions.some(sanction => sanction.withholdResult));
        });
    }

    getCourseStatusIcon(course) {
        return `curtin-icon-${this.courseStatusIcons[(course.academicStatusCode || '').toUpperCase()]}`;
    }

    getStudyPeriodTitle(studyPeriod) {
        return studyPeriod.academicYear !== 'ALL'
            ? `${studyPeriod.academicYear}, ${studyPeriod.name}`
            : studyPeriod.name;
    }

    getStudentCourseProgression(studentId) {
        return this.studentService.getStudentCourseProgression(studentId).catch(err => {
            if (!err || err.status !== 403)
                return this.promise.reject(err);
            this.shouldWithholdResult = true;
        });
    }
}