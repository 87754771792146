DecoratorConfig.$inject = [ '$provide' ];

export function DecoratorConfig($provide) {
    $provide.decorator('$state', stateDecorator);
    $provide.decorator('CacheFactory', cacheFactoryDecorator);
}

stateDecorator.$inject = ['$delegate', 'NavigatorService'];
function stateDecorator(delegate, navigatorService) {
    delegate.goBack = (options) => {
        const previous = navigatorService.getLastState() || DefaultState;
        return delegate.go(previous.state.name, previous.params, Object.assign( { inherit: false }, options));
    };
    return delegate;
}

cacheFactoryDecorator.$inject = [ '$delegate', 'WebApiService' ];
function cacheFactoryDecorator(delegate, webApi) {
    delegate.createApiCache = function(config) {
        return delegate.create(config.name, {
            ttl: config.defaultTtl,
            storage: config.storage,
            fetch: get
        });
    };

    return delegate;

    function get(key, cache) {
        // Default fetch action is to treat the key as a url and GET it
        return webApi.get(key).then(res => {
            // Only cache if the response was a 200
            if (res.status === 200)
                cache.put(key, res.data);
            return res.data;
        });
    }
}