import { inject } from 'core';

@inject('$window')
export class AppInsights {
    constructor(window) {
        this.insights = window.appInsights
    }

    isEnabled() {
        return !!this.insights;
    }

    initialise() {
        this.isEnabled() && this.insights.trackEvent('ClientApplicationInitialisation');
    }

    setUser(userId) {
        return this.isEnabled() && this.insights.setAuthenticatedUserContext(userId);
    }

    clearUser() {
        return this.isEnabled() && this.insights.clearAuthenticatedUserContext();
    }

    trackPageView(name, url, properties, measurements, duration) {
        return this.isEnabled() && this.insights.trackPageView(name, url, properties, measurements, duration);
    }

    trackEvent(event, props) {
        return this.isEnabled() && this.insights.trackEvent(event, props);
    }

    trackTrace(message, properties, severity) {
        return this.isEnabled() && this.insights.trackTrace(message, properties, severity);
    }

    // --
    // Exception tracking
    // --

    trackVerbose(exception, handler, properties) {
        return this.trackException(exception, handler, properties, 0);
    }

    trackInformation(exception, handler, properties) {
        return this.trackException(exception, handler, properties, 1);
    }

    trackWarning(exception, handler, properties) {
        return this.trackException(exception, handler, properties, 2);
    }

    trackError(exception, handler, properties) {
        return this.trackException(exception, handler, properties, 3);
    }

    trackCritical(exception, handler, properties) {
        return this.trackException(exception, handler, properties, 4);
    }

    trackException(exception, handler, properties, severity) {
        return this.isEnabled() && this.insights.trackException(exception, handler, properties, null, severity);
    }

    // --
    // Utils
    // --
    createErrorHandler(handler) {
        return err => this.trackError(err, handler, {});
    }
};