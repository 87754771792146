import { inject } from 'core';
import { groupBy, watchOnce, DateWrapper } from 'elsie/utils';

const FourteenDays = 14 * 24 * 60 * 60 * 1000; // ms precision to compare with dates

const DueSoon = 'Due Soon';
const DueLater = 'Due Later';
const DueDateMissing = 'Unknown Due Date';

@inject('$scope')
export class AssessmentsOverviewUpcomingView {
    constructor(scope) {
        watchOnce(scope, 'assessments', assessments => {
            const now = DateWrapper.new();
            const futureAssessments = assessments.filter(a => !a.dueDateTime || a.dueDateTime > now);

            // Add an 'id' field to each upcoming group to give it a HTML-friendly identifier
            this.upcoming = groupBy(futureAssessments, dueDateKeySelector);
            this.upcoming.forEach(group => group.id = group.key.toLowerCase().replace(/\s+/g, '-'));
            this.assessments = assessments;
        });
    }

    hasAssessmentsWithoutDueDates() {
        return this.assessments && this.assessments.some(a => !a.dueDateTime);
    }

    getNumberOfAssessmentsWithoutDueDates() {
        return this.assessments && this.assessments.reduce((total, a) => total + (a.dueDateTime ? 0 : 1), 0);
    }
}

function dueDateKeySelector(assessment) {
    const now = DateWrapper.new();

    return !assessment.dueDateTime
        ? DueDateMissing
        : ((assessment.dueDateTime - now) <= FourteenDays ? DueSoon : DueLater);
}