import { inject } from 'core';
import { DialogResult } from 'ng-modal-dialog';
import { DateWrapper } from 'elsie/utils';
import { addYears, format, isSameYear } from 'date-fns';

@inject('$scope', '$modalDialogParams')
export class DateTimePickerDialog {
    constructor(scope, modalDialogParams) {
        this.today = DateWrapper.new();
        this.nextYear = addYears(this.today, 1);
        this.scope = scope;

        this.date = modalDialogParams.date ? new Date(modalDialogParams.date.getTime()) : this.today;
        this.readonlyView = modalDialogParams.readonlyView;
        this.time = new Date(this.date.getTime());

        this.setView(modalDialogParams.initialView || 'date');
    }

    save() {
        this.scope.close(DialogResult.Success, this.date);
    }

    cancel() {
        this.scope.close(DialogResult.Cancelled);
    }

    getFormattedDate() {
        return isSameYear(this.today, this.date)
            ? format(this.date, 'dddd D MMMM')
            : format(this.date, 'ddd, D MMMM YYYY');
    }

    getFormattedTime() {
        return format(this.time, 'h:mm a');
    }

    onTimeChanged(time) {
        this.date.setHours(time.getHours());
        this.date.setMinutes(time.getMinutes());
    }

    onDateChanged(date) {
        date.setHours(this.time.getHours());
        date.setMinutes(this.time.getMinutes());
    }

    setView(view, $event) {
        if (view === this.readonlyView)
            $event && $event.preventDefault();
        else 
            this.view = view;
    }

    isInView(view) {
        return this.view === view;
    }
}