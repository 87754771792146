export class AuthConfigProvider {
    constructor() {
        this.defaultReturnUrl = '/';
        this.reloadOnLogin = false;
    }

    setDefaultReturnUrl(url) {
        this.defaultReturnUrl = url;
    }

    setReloadOnLogin(reloadOnLogin) {
        this.reloadOnLogin = reloadOnLogin;
    }

    $get() {
        return {
            defaultReturnUrl: this.defaultReturnUrl,
            reloadOnLogin: this.reloadOnLogin
        };
    }
}