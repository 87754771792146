export const WellnessMessages = [
    "Exercising makes you both more productive and happier for the rest of the day, so get your heart pumping!",
    "Our greatest weakness lies in giving up. The most certain way to succeed is always to try just one more time. (Thomas A. Edison)",
    "Getting a good night’s sleep allows your body time to repair, regenerate and rebuild cells.",
    "If you hear a voice in you say ‘you can’t do it,’ by all means do it, and that voice will be silenced.",
    "If you can, go outside regularly for some fresh air. It helps keep your mind clear.",
    "Laughing lowers levels of stress hormones and strengthens the immune system.",
    "I can accept failure, everyone fails at something. But I can’t accept not trying. (Michael Jordan)",
    "Learn from yesterday, live for today, hope for tomorrow. (Albert Einstein)",
    "Whatever you can do, or dream you can do, begin it. Boldness has genius, power, and magic in it. Begin it now. (Goethe)",
    "The mind and body are not separate. What affects one, affects the other. Be kind to both.",
    "Believe in yourself and all that you are. Know that there is something inside you that is greater than any obstacle.",
    "To maximise your success, remember: Exercise light, sleep tight, eat right.",
    "Your brain needs fuel too! Have a good breakfast or lunch before you start studying.",
    "Try to learn something about everything and everything about something. (Thomas Henry Huxley)",
    "%1$s, the best way to predict the future is to create it. (Abraham Lincoln)",
    "Life is 10 per cent what happens and 90 per cent how you deal with it. (Jim Stynes)",
    "There is only one way to avoid criticism: do nothing, say nothing, and be nothing. (Aristotle)",
    "%1$s, whether you think you can or you think you can’t, you’re right. (Henry Ford)",
    "Creativity is intelligence having fun (Albert Einstein)",
    "%1$s, the best time to plant a tree was 20 years ago. The second best time is now. (Chinese Proverb)",
    "%1$s, the beautiful thing about learning is nobody can take it away from you. (B B King)",
    "Every child is an artist. The problem is how to remain an artist once he grows up (Pablo Picasso)",
    "%1$s, any fool can know. The point is to understand. (Albert Einstein)",
    "I am always doing that which I cannot do, in order that I may learn how to do it. (Pablo Picasso)",
    "%1$s, there is no substitute for hard work. (Thomas Edison)",
    "%1$s, he who laughs most, learns best (John Cleese)",
    "%1$s, a journey of a thousand miles starts with a single step. (Lao Tzu)",
    "%1$s, live as if you were to die tomorrow. Learn as if you were to live forever. (M K Gandhi)",
    "%1$s, if the wind will not serve, take to the oars. (Latin Proverb)",
    "A mathematician is a device for turning coffee into theorems. (Paul Erdos)",
    "Get out of bed, go to school, stick at school. Make it happen for yourself because those opportunities are waiting. (Andrew Forrest)",
    "You don’t have to be anyone special to achieve something big. You just have to want to do it. (Jessica Watson)",
    "%1$s, you can never be overdressed or overeducated. (Oscar Wilde)",
    "%1$s, it does not matter how slowly you go as long as you do not stop. (Confucius)",
    "%1$s, quality is not an act, it is a habit. (Aristotle)",
    "%1$s, those who don’t know history are doomed to repeat it. (Edmund Burke)",
    "We become what we think about. (Earl Nightingale)",
    "To be successful you must surround yourself with capable people and ask lots of advice. (Dick Smith)",
    "Human beings have an inalienable right to invent themselves. (Germaine Greer)",
    "%1$s, it’s only when you are put at full stretch that you can realise your full potential. (Sir Edward (Weary) Dunlop)",
    "To know oneself is to study oneself in action with another person. (Bruce Lee)",
    "%1$s, tough times never last, but tough people do. (Dr. Robert Schuller)",
    "%1$s, the best teachers are those that show you where to look but don’t tell you what to see. (Alexandra K. Trenfor)",
    "%1$s, education is a progressive discovery of our own ignorance. (Will Durant)",
    "%1$s, the important thing is not to stop questioning. (Albert Einstein)",
    "It’s not that I’m so smart, it’s just that I stay with problems longer. (Albert Einstein)",
    "I would like to die on Mars. Just not on impact. (Elon Musk)",
    "There is nothing in a caterpillar that tells you it’s going to be a butterfly. (Buckminster Fuller)",
    "%1$s, only the educated are free. (Epictetus)",
    "Outside of a dog, a book is man’s best friend. Inside of a dog it’s too dark to read. (Groucho Marx)",
    "%1$s, mistakes are always forgivable, if one has the courage to admit them. (Bruce Lee)",
    "%1$s, when something is important enough, you do it even if the odds are not in your favor. (Elon Musk)",
    "%1$s, kindness is the language which the deaf can hear and the blind can see. (Mark Twain)",
    "The function of education is to teach one to think intensively and to think critically. Intelligence plus character - that is the goal of true education. (Martin Luther King)",
    "%1$s, I’m not in this world to live up to your expectations and you’re not in this world to live up to mine. (Bruce Lee)",
    "Nothing in all the world is more dangerous than sincere ignorance and conscientious stupidity. (Martin Luther King)",
    "%1$s, we make the future sustainable when we invest in the poor, not when we insist on their suffering. (Bill Gates)",
    "%1$s, by failing to prepare, you are preparing to fail. (Benjamin Franklin)",
    "%1$s, our greatest glory is not in never falling, but in rising every time we fall. (Confucius)",
    "The harder I work, the luckier I get. (Gary Player)",
    "%1$s, if you fell down yesterday, stand up today. (H. G. Wells)",
    "%1$s, even a mistake may turn out to be the one thing necessary to a worthwhile achievement. (Henry Ford)",
    "%1$s, courage is being scared to death... and saddling up anyway. (John Wayne)",
    "Learning is not a spectator sport. (Anonymous)",
    "Anyone who stops learning is old, whether at twenty or eighty. Anyone who keeps learning stays young. The greatest thing in life is to keep your mind young. (Henry Ford)",
    "%1$s, if you spend too much time thinking about a thing, you’ll never get it done. (Bruce Lee)",
    "%1$s, chains of habit are too light to be felt until they are too heavy to be broken. (Warren Buffet)",
    "I believe that if you show people the problems and you show them the solutions they will be moved to act. (Bill Gates)",
    "I hear and I forget. I see and I remember. I do and I understand. (Confucius)",
    "%1$s, there is only one success: to be able to spend your life in your own way (Christopher Morley)",
    "%1$s, do the right thing. It will gratify some people and astonish the rest. (Mark Twain)",
    "The only thing we know about the future is that it will be different. (Peter Drucker)",
    "%1$s, what you do speaks so loudly that I cannot hear what you say (Ralph Waldo Emerson)",
    "If the only tool you have is a hammer, you tend to see every problem as a nail. (Abraham Maslow)",
    "%1$s, things work out best for those who make the best of how things work out. (John Wooden)",
    "%1$s, you must be the change you wish to see in the world. (M K Ghandi)",
    "Education is the ability to listen to almost anything without losing your temper or your self-confidence. (Robert Frost)",
    "%1$s, it always seems impossible until its done. (Nelson Mandela)",
    "I didn’t get there by wishing for it or hoping for it, but by working for it. (Estée Lauder) ",
    "I’d rather regret the things I’ve done than regret the things I haven’t done. (Lucille Ball) ",
    "Action is the foundational key to all success. (Pablo Picasso) ",
    "Done is better than perfect. (Sheryl Sandberg) ",
    "Optimism is the faith that leads to achievement. (Helen Keller) ",
    "I do not try to dance better than anyone else. I only try to dance better than myself. (Arianna Huffington) ",
    "You can’t be that kid standing at the top of the waterslide, overthinking it. You have to go down the chute. (Tina Fey) ",
    "We need to start work with the idea that we’re going to learn every day. I learn, even at my position, every single day. (Chanda Kochhar) ",
    "I need to listen well so that I hear what is not said. (Thuli Madonsela) ",
    "It’s not the absence of fear, it’s overcoming it. Sometimes you’ve got to blast through and have faith. (Emma Watson) ",
];