import { inject } from 'core';
import { getFacultyIdentifier } from 'elsie/utils';

@inject('$q','$state', 'SessionService', 'StudentService', 'ViewService')
export class ContactsDetailsView {
    constructor(promise, state, sessionService, studentService, viewService) {
        this.user = sessionService.current();
        this.promise = promise;
        this.state = state;
        this.studentService = studentService;

        this.$viewPromise = viewService.wrap(this.$init());
    }

    $init() {
        const requestedStaff = !!this.state.params.staffId;

        return this.$getContacts().then(contacts => {
            if (!contacts || !contacts.length)
                this.title = 'Unit Contacts';
            else {
                const facultyId = getFacultyIdentifier(contacts.unit.faculty);

                this.title = requestedStaff ? contacts[0].fullName : 'Unit Contacts';
                
                this.contacts = contacts.map(contact => Object.assign({}, contact, { 
                    title: contact.fullName, 
                    subtitle: contact.roles.join(' / '),
                    facultyId: facultyId,
                }));
            }
        })
    }

    $getContacts() {
        const { availabilityId, staffId } = this.state.params;
        const unitFilter = unit => unit.availabilityId == availabilityId;

        return this.studentService.getStudentUnitContacts(this.user.curtinId, unitFilter).then(unitContacts => {
            const contacts = unitContacts && unitContacts[0];
            if (!contacts || !staffId)
                return contacts;

            const filtered = contacts.filter(c => c.staffId.toLowerCase() === staffId.toLowerCase());
            filtered.unit = contacts.unit;
            return filtered;
        })
    }
}