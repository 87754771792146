import { inject } from 'core';
import { isSameDay, format } from 'date-fns';
import { getActivityType, flatten } from 'elsie/utils';

 // ms precision to compare with dates
const TwoDays = 2 * 24 * 60 * 60 * 1000;
const SevenDays = 7 * 24 * 60 * 60 * 1000;
const FourteenDays = SevenDays * 2;

@inject('$q', 'NoticeService', 'StudentService', 'LibraryService', 'AppInsights')
export class ReminderService {
    constructor(promise, noticeService, studentService, libraryService, appInsights) {
        this.promise = promise;
        this.noticeService = noticeService;
        this.studentService = studentService;
        this.libraryService = libraryService;
        this.appInsights = appInsights;
    }

    getReminders(studentId, date) {
        const handleFailure = this.appInsights.createErrorHandler('ReminderService.getReminders');

        // These promises are ordered by importance of the reminder.
        const promises = [
            this.getNotices(date).catch(handleFailure),
            this.getAssessmentReminders(studentId, date).catch(handleFailure),
            this.getStudyActivityReminders(studentId, date).catch(handleFailure),
            this.getLibraryReminders(studentId, date).catch(handleFailure)
        ];

        return this.promise.all(promises).then(flatten);
    }

    getNotices(date) {
        return this.noticeService.getActiveNotices(date).then(notices => {
            return notices.map(notice => new Reminder({
                    ref: notice,
                    type: 'notice',
                    title: notice.title,
                    description: notice.description
                }));
        });
    }

    getStudyActivityReminders(studentId, date) {
        return this.studentService.getStudentStudyActivities(studentId).then(activities => {
            // Find the first study activity in a two-day window and add that as the reminder
            const activity = activities && activities.sort((a1, a2) => a1.startDateTime - a2.startDateTime)
                .find(a => a.startDateTime > date && (a.startDateTime - date) <= TwoDays);

            if (activity) {
                const startFormat = isSameDay(activity.startDateTime, date) ? 'HH:mm' : 'ddd HH:mm';
                const endFormat = isSameDay(activity.startDateTime, activity.endDateTime) ? 'HH:mm' : 'ddd HH:mm';

                return [ new Reminder({
                    ref: activity,
                    type: getActivityType(activity),
                    title: activity.unit.abbreviatedTitle,
                    description: `${format(activity.startDateTime, startFormat)} - ${format(activity.endDateTime, endFormat)}`
                }) ];
            }
        });
    }

    getAssessmentReminders(studentId, date) {
        return this.studentService.getStudentAssessments(studentId).then(assessments => {
            // Only grab relevant assessments
            assessments = assessments && assessments.filter(createAssessmentFilter(date));

            if (assessments && assessments.length) {
                return [ new Reminder({
                    ref: assessments,
                    type: 'assessment',
                    title: `${assessments.length} upcoming assessment${assessments.length > 1 ? 's' : ''}`,
                    description: assessments.map(a => a.task).join(', ')
                }) ];
            }
        });
    }

    getLibraryReminders(studentId, date) {
        const promises = [
            this.libraryService.getStudentLoans(studentId),
            this.libraryService.getStudentRequests(studentId)
        ];

        return this.promise.all(promises).then(([loans, requests]) => {
            const reminders = [];
            const overdue = [];
            const dueSoon = [];
            const recalled = [];
            const availableRequests = requests && requests.filter(r => r.requestStatusCode === 'ON_HOLD_SHELF');

            loans && loans.sort((l1, l2) => l1.dueDateTime - l2.dueDateTime).forEach(loan => {
                if (loan.dueDateTime < date)
                    overdue.push(loan);
                else if (loan.statusCode === 'RECALL')
                    recalled.push(loan);
                else if ((loan.dueDateTime - date) <= SevenDays)
                    dueSoon.push(loan);
            });


            if (overdue.length) {
                reminders.push(new Reminder({
                    ref: overdue,
                    type: 'library-overdue',
                    title: getLibraryMessage(overdue, 'loaned') + ' overdue',
                    description: 'Please return ASAP'
                }));
            }

            if (dueSoon.length) {
                reminders.push(new Reminder({
                    ref: dueSoon,
                    type: 'library-upcoming',
                    title: getLibraryMessage(dueSoon, 'loaned') + ' due soon',
                    description: 'Return by ' + format(dueSoon[0].dueDateTime, 'dddd [at] h:mma')
                }));
            }

            if (recalled.length) {
                reminders.push(new Reminder({
                    ref: recalled,
                    type: 'library-recalled',
                    title: getLibraryMessage(recalled, 'loaned') + ' recalled',
                    description: 'Return by ' + format(recalled[0].dueDateTime, 'dddd dd/MM/YY [at] h:mma')
                }));
            }

            if (availableRequests && availableRequests.length) {
                reminders.push(new Reminder({
                    ref: availableRequests,
                    type: 'library-available',
                    title: getLibraryMessage(availableRequests, 'requested') + ' available',
                    description: 'Collect from ' + availableRequests[0].pickupLocation.name
                }));
            }

            return reminders;
        });
    }
}

function getLibraryMessage(items, type) {
    return `${items.length} ${type} item${items.length > 1 ? 's' : ''}`;
}

function createAcademicEntryFilter(date) {
    return entry => (entry.startDateTime - date) < TwoDays
        && entry.endDateTime >= date
        && entry.level === 'Alert'
        && (entry.dateTypeCode === 'TC' || entry.dateTypeCode === 'RP');
}

function createAssessmentFilter(date) {
    return assessment => assessment.dueDateTime 
        && assessment.dueDateTime > date 
        && (assessment.dueDateTime - date) <= FourteenDays;
}

class Reminder {
    constructor(opts) {
        this.ref = opts.ref;
        this.type = opts.type;
        this.title = opts.title;
        this.description = opts.description;
    }
}
