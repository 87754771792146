const Template = `
    <div class="notification-toast notification-toast--{{type}} notification-toast--clickable ng-hide" ng-show="show" ng-click="close()">
        <span class="notification-toast__message" ng-bind="message"></span>
    </div>
`;

export function NotificiationToastDirective(timeout, notificationService) {
    this.timeout = timeout;
    this.notificationService = notificationService;
}

NotificiationToastDirective.prototype = {
    restrict: 'A',
    template: Template,
    dependencies: [ '$timeout', 'NotificationService' ],
    link: function(scope) {
        let timeout = null;

        const createHandler = (type) => {
            return (message, opts) => {
                if (timeout) {
                    this.timeout.cancel(timeout);
                    timeout = null;
                }

                scope.type = type;
                scope.show = true;
                scope.message = message;

                if (opts && !isNaN(opts.timeout))
                    timeout = this.timeout(() => scope.show = false, opts.timeout);
            };
        };

        const types = [ 'success', 'info', 'warning', 'error' ];
        types.forEach(type => this.notificationService.on(type, createHandler(type)));

        scope.close = () => {
            scope.show = false;
        };
    }
};