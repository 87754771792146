
import config from 'config';

const DefaultState = config.navigation.defaultState;

const Template = `
    <div class="nav-control">
        <div class="nav-control__action">
            <a ng-if="showBackLink" ng-href="{{getBackLink()}}" ng-click="back($event)" title="Back" class="action-icon curtin-icon-arrow-tail-left"></a>
        </div>
        <div class="nav-control__title">
            <span ng-if="pageTitle" ng-bind="pageTitle"></span>
            <div ng-if="!pageTitle" ng-include="'${require('content/img/elsie.svg')}'" class="logo"></div>
        </div>
        <div class="nav-control__action">
            <div user-tray></div>
        </div>
    </div>
`;

export function StateNavigatorDirective(state, navigatorService, appInsights) {
    this.state = state;
    this.navigatorService = navigatorService;
    this.appInsights = appInsights;
}

StateNavigatorDirective.prototype = {
    restrict: 'EA',
    template: Template,
    scope: { pageTitle: '=', onBack: '&' },
    dependencies: ['$state', 'NavigatorService', 'AppInsights'],

    link: function(scope) {
        scope.showBackLink = this.state.current.name !== DefaultState.state.name;

        scope.getBackLink = () => {
            const previous = this.navigatorService.getLastState() || DefaultState;
            return this.state.href(previous.state.name, previous.params, { inherit: false });
        };

        scope.back = ($event) => {
            const previous = this.navigatorService.getLastState() || DefaultState;
            this.appInsights.trackEvent('ClientNavigationBack', { fromPage: this.state.current.name, toPage: previous.state.name });
            scope.onBack({ $event });
        };
    }
};
