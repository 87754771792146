import { inject } from 'core';
import Fuse from 'fuse.js';
import { flatten } from 'elsie/utils';

export function AutoCompleteDirective() { };

const ResultLimit = 5;
const SearchOptions = {
    includeMatches: true,
    shouldSort: true,
    tokenize: true,
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 2,
    matchAllTokens: true,
    keys: [
        "searchValue"
    ]
};

@inject('$scope', '$timeout', '$document', '$element')
export class AutoCompleteController {
    constructor(scope, timeout, document, element) {
        this.scope = scope;
        this.timeout = timeout;
        this.document = document[0];
        this.element = element[0];

        this.results = [];
        this.show = false;

        const documentClickHandler = event => {
            if (!this.element.contains(event.target)) {
                this.scope.$apply(() => this.show = false);
                this.element.querySelector('input').blur();
            }
        };

        scope.$watch('query', query => query && this.search(query));

        this.document.addEventListener('click', documentClickHandler);
        this.scope.$on('$destroy', () => this.document.removeEventListener('click', documentClickHandler));
        
        this.fuse = new Fuse(this.scope.values, SearchOptions);
    }

    change() {
        this.query = this.text;
        this.results = this.fuse.search(this.text).slice(0, ResultLimit);
        this.show = true;
    }

    select(record) {
        this.scope.onSelected({ result: record.item });
        this.text = record.item.searchValue;
        this.change();
        this.show = false;
    }

    submit() {
        this.results && this.results.length && this.select(this.results[0]);
    }

    highlightRecord(record, key) {
        const match = record.matches.find(m => m.key === key);
        return match ? highlight(record.item[key], match.indices) : record.item[key];
    }
    
    search(query) {
        this.text = query;
        this.results = this.fuse.search(this.text).slice(0, ResultLimit);

        if (this.results.length > 0)
            this.select(this.results[0]);
    }
}

AutoCompleteDirective.prototype = {
    restrict: 'A',
    templateUrl: require('./auto-complete.html'),
    controller: AutoCompleteController,
    controllerAs: 'vm',
    scope: {
        onSelected: '&',
        values: '=',
        query: '='
    }
}

function highlight(string, locations) {
    var highlighted = '';
    var lastPos = 0;
    
    // If you want to enforce ordering
    // locations.sort((x, y) => x[0] - y[0]);

    locations.forEach(pos => {
        // Indices are for the first and last char of the match, so to highlight the whole
        // string you'd need to go one character further
        highlighted += `${string.substring(lastPos, pos[0])}<span class="highlight">${string.substring(pos[0], pos[1] + 1)}</span>`;
        lastPos = pos[1] + 1;
    });

    return (lastPos < string.length)
        ? highlighted + string.substring(lastPos)
        : highlighted;
}