import { inject } from 'core';

@inject('SessionService', 'CommunicationsService', 'ViewService')
export class CommunicationsDashboardAlertsView {
    constructor(sessionService, communicationsService, viewService) {
        this.communicationsService = communicationsService;
        this.user = sessionService.current();

        this.$viewPromise = viewService.wrap(this.$init());
    }

    $init() {
        return this.communicationsService.getStudentAlerts(this.user.curtinId)
            .then(alerts => this.alerts = alerts)
    }
}