import { inject } from 'core';

@inject('$window', 'SessionService')
export class GoogleAnalytics {
    constructor(window, sessionService) {
        this.window = window;
        this.sessionService = sessionService;
    }

    trackPageView(path, name) {
        if (!path)
            throw new TypeError('GoogleAnalytics: Path is required');

        if (this.window.dataCoreLayer && typeof(this.window.dataCoreLayer.push) === "function") {
            const user = this.sessionService.current();
            const pageViewEvent = {
                event: 'pageView',
                pageURL: path
            };

            if (name){
                pageViewEvent.pageTitle = name;
            }
            if (user && user.curtinId) {
                pageViewEvent.user = user.curtinId;
            }

            dataCoreLayer.push(pageViewEvent);
        }
    }
};