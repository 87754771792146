import { inject } from 'core';
import { FriendlyError } from 'core-ui';
import { getFacultyIdentifier } from 'elsie/utils';

const PassFailResultType = 'Pass/Fail';

@inject('$q', '$state', '$stateParams', 'SessionService', 'UnitService', 'StudentService', 'ViewService')
export class UnitsDetailsView {
    constructor(promise, state, stateParams, sessionService, unitService, studentService, viewService) {
        this.promise = promise;
        this.state = state
        this.user = sessionService.current();
        this.availabilityId = stateParams.availabilityId;
        this.unitService = unitService;
        this.studentService = studentService;

        this.$viewPromise = viewService.wrap(this.$init());
    }

    $init() {
        return this.studentService.getAllStudentUnits(this.user.curtinId).then(units => {
            const unitIndex = units.findIndex(u => u.availabilityId == this.availabilityId);

            if (unitIndex < 0) 
                throw new FriendlyError('Unknown unit.');
            else {
                this.unitIndex = unitIndex;
                this.unit = units[unitIndex];

                this.loadingOutline = true;

                // Deliberately omitting a return here,
                // the UI handles the Unit Outline loading
                this.unitService.getAvailabilityOutline(this.unit.availabilityId, this.unit.attendanceModeCode)
                    .then(unitOutline => this.unitOutline = unitOutline)
                    .finally(() => this.loadingOutline = false);
            }
        });
    }

    getAttendenceMode() {
        return this.unit.attendanceMode ? `${this.unit.attendanceMode} Attendance` : 'Attendance N/A';
    }

    getFacultyClass() {
        return `faculty-${getFacultyIdentifier(this.unit.faculty)}${(this.unitIndex%5)+1}`;
    }

    hasGrade() {
        return  this.unit.grade && this.unit.grade.length;
    }

    getUnitResultText() {
        return this.unit.resultType === PassFailResultType 
            || this.unit.statusCode === this.unit.grade.toUpperCase()
                ? this.unit.statusCode
                : `${this.unit.status.toUpperCase()} (${this.unit.mark}/100)`;
    }

    getUnitMark() {
        return this.unit.resultType === PassFailResultType
            ? null
            : this.unit.mark;
    }
}
