import { inject } from 'core';
import { flatten, isActiveUnit, toMap, transformDateProps } from 'elsie/utils';
import config from 'config';

@inject('$q', 'UnitService', 'CacheFactory')
export class StudentService {
    constructor(promise, unitService, cacheFactory) {
        const cacheConfig = config.cache.studentService;

        this.promise = promise;
        this.unitService = unitService;

        this.cache = cacheFactory.createApiCache(cacheConfig);
    }
    
    getAllStudentUnits(studentId) {
        return this.cache.get(`/students/${encodeURIComponent(studentId)}/units`);
    }

    getActiveStudentUnits(studentId) {
        // TODO: Worth caching?
        return this.getAllStudentUnits(studentId)
            .then(units => units && units.filter(isActiveUnit));
    }
    
    getStudentStudyActivities(studentId) {
        return this.cache.get(`/students/${encodeURIComponent(studentId)}/study-activities`)
            .then(activities => transformDateProps(activities, [ 'startDateTime', 'endDateTime' ]));
    }

    getStudentCourseProgression(studentId) {
        return this.cache.get(`/students/${encodeURIComponent(studentId)}/course-progression`);
    }

    getStudentSanctions(studentId) {
        return this.cache.get(`/students/${encodeURIComponent(studentId)}/sanctions`);
    }

    getStudentAssessments(studentId) {
        return this.getActiveStudentUnits(studentId).then(units => {
            const promises = units.map(unit => this.unitService.getAvailabilityAssessments(unit.availabilityId).then(assessments => {
                // Use Object.assign here to prevent the cached object having a 'unit' object attached to it.
                return assessments && assessments.map(a => Object.assign({}, a, { unit }));
            }));

            return this.promise.all(promises).then(flatten);
        });
    }

    getStudentUnitContacts(studentId, unitFilterFn) {
        const promises = [
            this.getAllStudentUnits(studentId),
            this.getStudentStudyActivities(studentId)
        ];

        return this.promise.all(promises).then(([units, activities]) => {
            if (typeof(unitFilterFn) === 'function')
                units = units.filter(unitFilterFn);

            return this.promise.all(units.map(unit => {
                return this.unitService.getAvailabilityOutline(unit.availabilityId, unit.attendanceModeCode).then(unitOutline => {
                    return groupContacts(unit, unitOutline, activities);
                });
            }));
        });
    }

    getStudentUnitAnnouncements(studentId) {
        return this.getActiveStudentUnits(studentId).then(units => {
            const promises = units.map(u => this.unitService.getAvailabilityAnnouncements(u.availabilityId));

            return this.promise.all(promises).then(results => {
                const unitMap = toMap(units, u => u.availabilityId);
                const aggregated = [];
                
                // Aggregate all the announcements, unique by 'announcementId'
                results.forEach(announcements => {
                    announcements && announcements.forEach(announcement => {
                        if (!aggregated.some(a => a.announcementId === announcement.announcementId)) {
                            const unit = unitMap[announcement.unitAvailabilityId];
                            announcement.unitCode = unit && unit.code;
                            announcement.faculty = unit && unit.faculty;

                            aggregated.push(announcement);
                        }
                    });
                });

                return aggregated.sort((a1, a2) => a2.publicationStartDateTime - a1.publicationStartDateTime);
            });
        });
    }
}

function groupContacts(unit, unitOutline, activities) {
    const unitActivities = activities.filter(a => a.unit.availabilityId == unit.availabilityId);
    const contacts = [];
    
    [unitOutline].concat(unitActivities).forEach(obj => {
        obj && obj.contacts.forEach(contact => {
            if (contact.staffId) {
                const existing = contacts.find(c => c.staffId.toLowerCase() == contact.staffId.toLowerCase());
                const merged = mergeContact(existing || {}, contact);

                if (!existing)
                    contacts.push(merged);
            }
        })
    });

    contacts.unit = unit;
    return contacts;
}

function mergeContact(c1, c2) {
    Object.keys(c2).forEach(prop => {
        if (prop !== 'role')
            c1[prop] = c2[prop];
        else {
            if (!c1.hasOwnProperty('roles'))
                c1.roles = [];
            if (c1.roles.indexOf(c2[prop]) < 0) 
                c1.roles.push(c2[prop]);
        }
    });
    return c1;
}
