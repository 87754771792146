import { inject } from 'core';
import { format } from 'date-fns'

export function ReminderListDirective() { }

@inject('$scope', '$state')
class ReminderListController {
    constructor(scope, state) {
        this.reminders = scope.reminders;
        this.state = state;
    }

    getEntryUrl(reminder) {
        switch(reminder.type) {
            case 'assessment': 
                return this.state.href('assessments.overview.upcoming');
            case 'academic': 
                return this.state.href('planner.calendar', { date: format(reminder.ref.startDateTime, 'YYYY-MM-DD') });
            case 'library-overdue':
            case 'library-upcoming':
            case 'library-recalled':
            case 'library-available':
                return this.state.href('library');
            case 'notice':
                return this.state.href('notice', { id: reminder.ref.id })
            default: 
                return this.state.href('planner.activity', { activityId: reminder.ref.activityId });
        }
    }

    getIconClass(reminder) {
        switch(reminder.type) {
            case 'assessment': 
            case 'academic': 
                return 'curtin-icon-agenda-info';
            case 'library-overdue': 
            case 'library-upcoming':
            case 'notice':
                return 'curtin-icon-agenda-alert';
            case 'library-recalled':
                return 'curtin-icon-reply';
            case 'library-available':
                return 'curtin-icon-correct';
            default: 
                return 'curtin-icon-agenda-clock';
        }
    }
}

ReminderListDirective.prototype = {
    restrict: 'A',
    templateUrl: require('./reminder-list.html'),
    scope: { 
        reminders: '='
    },
    controller: ReminderListController,
    controllerAs: 'vm'
};
