export const TimeZoneAbbreviations = {
    "Australian Central Daylight Savings Time": "ACDT",
    "Australian Central Daylight Time": "ACDT",
    "Australian Central Standard Time": "ACST",
    "Acre Time": "ACT",
    "ASEAN Common Time": "ACT",
    "Australian Central Western Standard Time": "ACWST",
    "Atlantic Daylight Time": "ADT",
    "Australian Eastern Daylight Savings Time": "AEDT",
    "Australian Eastern Daylight Time": "AEDT",
    "Australian Eastern Standard Time": "AEST",
    "Afghanistan Time": "AFT",
    "Alaska Daylight Time": "AKDT",
    "Alaska Standard Time": "AKST",
    "Amazon Summer Time": "AMST",
    "Amazon Time": "AMT",
    "Armenia Time": "AMT",
    "Argentina Time": "ART",
    "Arabia Standard Time": "AST",
    "Atlantic Standard Time": "AST",
    "Australian Western Standard Time": "AWST",
    "Azores Summer Time": "AZOST",
    "Azores Standard Time": "AZOT",
    "Azerbaijan Time": "AZT",
    "Brunei Time": "BDT",
    "British Indian Ocean Time": "BIOT",
    "Baker Island Time": "BIT",
    "Bolivia Time": "BOT",
    "Brasília Summer Time": "BRST",
    "Brasilia Time": "BRT",
    "Bangladesh Standard Time": "BST",
    "Bougainville Standard Time": "BST",
    "British Summer Time": "BST",
    "Bhutan Time": "BTT",
    "Central Africa Time": "CAT",
    "Cocos Islands Time": "CCT",
    "Central Daylight Time": "CDT",
    "Cuba Daylight Time": "CDT",
    "Central European Summer Time": "CEST",
    "Central European Time": "CET",
    "Chatham Daylight Time": "CHADT",
    "Chatham Standard Time": "CHAST",
    "Choibalsan Standard Time": "CHOT",
    "Choibalsan Summer Time": "CHOST",
    "Chamorro Standard Time": "CHST",
    "Chuuk Time": "CHUT",
    "Clipperton Island Standard Time": "CIST",
    "Central Indonesia Time": "CIT",
    "Cook Island Time": "CKT",
    "Chile Summer Time": "CLST",
    "Chile Standard Time": "CLT",
    "Colombia Summer Time": "COST",
    "Colombia Time": "COT",
    "Central Standard Time": "CST",
    "China Standard Time": "CST",
    "Cuba Standard Time": "CST",
    "China Time": "CT",
    "Cape Verde Time": "CVT",
    "Central Western Standard Time": "CWST",
    "Christmas Island Time": "CXT",
    "Davis Time": "DAVT",
    "Dumont d'Urville Time": "DDUT",
    "AIX-specific equivalent of Central European Time[NB 1]": "DFT",
    "Easter Island Summer Time": "EASST",
    "Easter Island Standard Time": "EAST",
    "East Africa Time": "EAT",
    "Eastern Caribbean Time": "ECT",
    "Ecuador Time": "ECT",
    "Eastern Daylight Time": "EDT",
    "Eastern European Summer Time": "EEST",
    "Eastern European Time": "EET",
    "Eastern Greenland Summer Time": "EGST",
    "Eastern Greenland Time": "EGT",
    "Eastern Indonesian Time": "EIT",
    "Eastern Standard Time": "EST",
    "Further-eastern European Time": "FET",
    "Fiji Time": "FJT",
    "Falkland Islands Summer Time": "FKST",
    "Falkland Islands Time": "FKT",
    "Fernando de Noronha Time": "FNT",
    "Galápagos Time": "GALT",
    "Gambier Islands Time": "GAMT",
    "Georgia Standard Time": "GET",
    "French Guiana Time": "GFT",
    "Gilbert Island Time": "GILT",
    "Gambier Island Time": "GIT",
    "Greenwich Mean Time": "GMT",
    "South Georgia and the South Sandwich Islands Time": "GST",
    "Gulf Standard Time": "GST",
    "Guyana Time": "GYT",
    "Hawaii–Aleutian Daylight Time": "HDT",
    "Heure Avancée d'Europe Centrale French-language name for CEST": "HAEC",
    "Hawaii–Aleutian Standard Time": "HST",
    "Hong Kong Time": "HKT",
    "Heard and McDonald Islands Time": "HMT",
    "Khovd Summer Time": "HOVST",
    "Khovd Standard Time": "HOVT",
    "Indochina Time": "ICT",
    "International Day Line West time zone": "IDLW",
    "Israel Daylight Time": "IDT",
    "Indian Ocean Time": "IOT",
    "Iran Daylight Time": "IRDT",
    "Irkutsk Time": "IRKT",
    "Iran Standard Time": "IRST",
    "Indian Standard Time": "IST",
    "Irish Standard Time": "IST",
    "Israel Standard Time": "IST",
    "Japan Standard Time": "JST",
    "Kaliningrad Time": "KALT",
    "Kyrgyzstan Time": "KGT",
    "Kosrae Time": "KOST",
    "Krasnoyarsk Time": "KRAT",
    "Korea Standard Time": "KST",
    "Lord Howe Standard Time": "LHST",
    "Lord Howe Summer Time": "LHST",
    "Line Islands Time": "LINT",
    "Magadan Time": "MAGT",
    "Marquesas Islands Time": "MART",
    "Mawson Station Time": "MAWT",
    "Mountain Daylight Time": "MDT",
    "Middle European Time Same zone as CET": "MET",
    "Middle European Summer Time Same zone as CEST": "MEST",
    "Marshall Islands Time": "MHT",
    "Macquarie Island Station Time": "MIST",
    "Marquesas Islands Time": "MIT",
    "Myanmar Standard Time": "MMT",
    "Moscow Time": "MSK",
    "Malaysia Standard Time": "MST",
    "Mountain Standard Time": "MST",
    "Mauritius Time": "MUT",
    "Maldives Time": "MVT",
    "Malaysia Time": "MYT",
    "New Caledonia Time": "NCT",
    "Newfoundland Daylight Time": "NDT",
    "Norfolk Island Time": "NFT",
    "Nepal Time": "NPT",
    "Newfoundland Standard Time": "NST",
    "Newfoundland Time": "NT",
    "Niue Time": "NUT",
    "New Zealand Daylight Time": "NZDT",
    "New Zealand Standard Time": "NZST",
    "Omsk Time": "OMST",
    "Oral Time": "ORAT",
    "Pacific Daylight Time": "PDT",
    "Peru Time": "PET",
    "Kamchatka Time": "PETT",
    "Papua New Guinea Time": "PGT",
    "Phoenix Island Time": "PHOT",
    "Philippine Time": "PHT",
    "Pakistan Standard Time": "PKT",
    "Saint Pierre and Miquelon Daylight Time": "PMDT",
    "Saint Pierre and Miquelon Standard Time": "PMST",
    "Pohnpei Standard Time": "PONT",
    "Pacific Standard Time": "PST",
    "Philippine Standard Time": "PST",
    "Paraguay Summer Time": "PYST",
    "Paraguay Time": "PYT",
    "Réunion Time": "RET",
    "Rothera Research Station Time": "ROTT",
    "Sakhalin Island Time": "SAKT",
    "Samara Time": "SAMT",
    "South African Standard Time": "SAST",
    "Solomon Islands Time": "SBT",
    "Seychelles Time": "SCT",
    "Samoa Daylight Time": "SDT",
    "Singapore Time": "SGT",
    "Sri Lanka Standard Time": "SLST",
    "Srednekolymsk Time": "SRET",
    "Suriname Time": "SRT",
    "Samoa Standard Time": "SST",
    "Singapore Standard Time": "SST",
    "Showa Station Time": "SYOT",
    "Tahiti Time": "TAHT",
    "Thailand Standard Time": "THA",
    "Indian/Kerguelen": "TFT",
    "Tajikistan Time": "TJT",
    "Tokelau Time": "TKT",
    "Timor Leste Time": "TLT",
    "Turkmenistan Time": "TMT",
    "Turkey Time": "TRT",
    "Tonga Time": "TOT",
    "Tuvalu Time": "TVT",
    "Ulaanbaatar Summer Time": "ULAST",
    "Ulaanbaatar Standard Time": "ULAT",
    "Coordinated Universal Time": "UTC",
    "Uruguay Summer Time": "UYST",
    "Uruguay Standard Time": "UYT",
    "Uzbekistan Time": "UZT",
    "Venezuelan Standard Time": "VET",
    "Vladivostok Time": "VLAT",
    "Volgograd Time": "VOLT",
    "Vostok Station Time": "VOST",
    "Vanuatu Time": "VUT",
    "Wake Island Time": "WAKT",
    "West Africa Summer Time": "WAST",
    "West Africa Time": "WAT",
    "Western European Summer Time": "WEST",
    "Western European Time": "WET",
    "Western Indonesian Time": "WIT",
    "Western Standard Time": "WST",
    "Yakutsk Time": "YAKT",
    "Yekaterinburg Time": "YEKT"
};