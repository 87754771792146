import angular from 'angular';
import coreLib from 'core/lib';

import { directive } from './directive';

import { ViewLoaderDirective } from './view-loader';
import { ProgressBarDirective, ProgressService, ProgressHttpInterceptor } from './progress';

import { CutFilter } from './filters/cut';

let lib = angular.module('core-ui.lib', [ coreLib ])
    .config(ProgressHttpInterceptor)
    .service('ProgressService', ProgressService)

    .filter('cut', () => CutFilter)
    .filter('raw', [ '$sce', (sce) => sce.trustAsHtml ])
    .filter('urlsafe', () => encodeURIComponent)
    .directive('uiView', directive(ViewLoaderDirective))
    .directive('progressBar', directive(ProgressBarDirective));

export default lib.name;