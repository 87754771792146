// Can't @inject functions
RouteConfig.$inject = ['$stateProvider', '$urlMatcherFactoryProvider'];

export function RouteConfig(stateProvider, urlMatcherFactory) {
    // Set the case to insensitive matching when defining these routes.
    let oldCaseInsensitive = urlMatcherFactory.caseInsensitive();
    urlMatcherFactory.caseInsensitive(true);

    stateProvider
        .state('login', {
            url: '/login',
            templateUrl: require('../views/login.html'),
            params: { expired: false, returnTo: null, logout: false },
            controllerAs: 'vm',
            controller: 'LoginView'
        })
        .state('logout', {
            url: '/logout',
            templateUrl: require('../views/logout.html'),
            controllerAs: 'vm',
            controller: 'LogoutView'
        })
        .state('denied', {
            url: '/denied',
            params: { returnTo: null },
            controller: [ '$state', '$scope', (state, scope) => {
                scope.returnTo = state.params.returnTo;
            }],
            templateUrl: require('../views/denied.html')
        });

    // Reset the case insensitivity setting
    urlMatcherFactory.caseInsensitive(oldCaseInsensitive);
}