import { inject } from 'core';

@inject('$state', 'SessionService')
export class LogoutView {
    constructor(state, sessionService) {
        this.state = state;
        this.sessionService = sessionService;
        this.logout();
    }

    logout() {
        this.submitting = true;
        this.errors = null;

        this.sessionService.logout().then(res => {
            if (res.status !== 200) 
                this.errors = res.errors;
            else
                this.state.go('login', { logout: true })

        }).finally(() => this.submitting = false);
    }
}