import jwtdecode from 'jwt-decode';
import { inject } from 'core';

const StorageKey = 'elsie-user';

@inject('$q', '$rootScope', 'WebApiService', 'StorageService')
export class SessionService {
    constructor(promise, rootScope, webApiService, storageService) {
        this.promise = promise;
        this.rootScope = rootScope;
        this.webApiService = webApiService;
        this.storageService = storageService;
    }

    current() {
        return this.storageService.get(StorageKey, null);
    }

    currentOrRefresh() {
        const user = this.current();

        if (user == null || Date.now() < user.expires)
            return this.promise.when(user);

        return this.refresh(user).then(res => {
            if (!res.data)
                this.clear();
            return res.data;
        }, () => this.clear());
    }

    clear() {
        this.storageService.clear();
        this.rootScope.$emit('$userSessionEnded');
    }

    singleSignIn() {
        return this.webApiService.post('/sessions/sso')
            .then(res => this.$completeLogin(res));
    }

    login(viewModel) {
        return this.webApiService.post('/sessions', viewModel)
            .then(res => this.$completeLogin(res));
    }

    logout() {
        return this.webApiService.delete('/sessions')
            .then(res => this.$completeLogout(res));
    }

    refresh(user) {
        return this.webApiService.post(`/sessions/refresh`, { token: user.accessToken })
            .then(res => this.$completeLogin(res, true));
    }

    $completeLogin(res, suppressEmit) {
        if (res.status === 200 || res.status === 201) {
            res.data = mapTokenToUser(res.data.token);

            if (!suppressEmit)
                this.rootScope.$emit('$userSessionStarted', res.data);
            this.storageService.set(StorageKey, res.data);
        }

        return res;
    }

    $completeLogout(res) {
        this.clear();
        return res;
    }
}

// TODO: Should this be on the server (i.e. session endpoints return this object)
//    I can't make up my mind (server is already sending you a token with all the info...)
function mapTokenToUser(token) {
    const jwt = jwtdecode(token);

    return {
        accessToken: token,
        token: jwt.token,
        curtinId: jwt.unique_name,
        preferredName: jwt.preferred_name,
        fullName: `${jwt.preferred_name} ${jwt.last_name}`,
        roles: jwt.role ? (Array.isArray(jwt.role) ? jwt.role : [ jwt.role ]) : [],
        expires: jwt.exp * 1000 // exp comes down in seconds, Date.now() is in milliseconds, this just makes it easier to compare on the fly
    };
}
