AddTransitionAuthentication.$inject = [ '$q', '$injector', '$transitions', 'SessionService', 'StorageService' ];

export function AddTransitionAuthentication(q, injector, transitionService, sessionService, storage) {
    const options = { priority: 400 };
    const criteria = { 
        // Only check states with a defined 'authenticate' data object
        to: state => state.data && typeof(state.data.authenticate) !== 'undefined' 
    };

    transitionService.onStart(criteria, (transition) => {
        const state = transition.to();
        const roles = state.data.roles ? state.data.roles.map(r => r.toLowerCase()) : null;
        const stateService = transition.router.stateService;
        const returnTo = { state: state, params: transition.params() };

        return q.when(shouldAuthenticateTransition(transition, state, injector)).then(shouldAuthenticate => {
            if (!shouldAuthenticate)
                return true; // No need for authentication

            // Is there a logged in user?
            const user = sessionService.current();
            if (user == null) 
                return stateService.target('login', { returnTo }, { location: 'replace' });

            // Is there a role check?
            if (roles !== null) {
                const userRoles = user.roles.map(r => r.toLowerCase());
                if (!userRoles.some(r => roles.indexOf(r) >= 0))
                    return stateService.target('denied', { returnTo });
            }

            // All good
            return true;
        });
    }, options);
}

function shouldAuthenticateTransition(transition, state, injector) {
    // Is the current state the target state?
    if (state.name !== transition.to().name)
        return false;

    if (typeof(state.data.authenticate) !== 'function' && !Array.isArray(state.data.authenticate))
        return !!state.data.authenticate;

    // TODO: there has to be a better way of doing this, but I can't find anything
    // in the transition that gives you the resolved token values without manually looking them
    // each up in the UI injector (which isn't the same as angular's injector).
    const uiInjector = transition.injector();
    const authenticate = state.data.authenticate;
    const resolvables = {};

    transition.getResolveTokens().forEach(token => {
        if (typeof(token) === 'string')
            resolvables[token] = uiInjector.getAsync(token);
    });

    return q.all(resolvables).then(locals => injector.invoke(authenticate, undefined, locals));
}