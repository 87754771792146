import { inject } from 'core';
import { subMinutes, isAfter, differenceInDays, addMinutes, format, isEqual } from 'date-fns';
import { filterMap, getActivityType, flatten, DateWrapper } from 'elsie/utils';

@inject('$q', 'StudentService', 'LibraryService', 'StudentEventService', 'AppInsights')
export class PlannerService {
    constructor(promise, studentService, libraryService, studentEventService, appInsights) {
        this.promise = promise;
        this.studentService = studentService;
        this.libraryService = libraryService;
        this.studentEventService = studentEventService;
        this.appInsights = appInsights;
    }

    getStudentEntries(studentId) {
        // As we're pulling from multiple sources, we don't want the whole
        // call to fail if a single service is down or returning invalid status codes
        const handleFailure = this.appInsights.createErrorHandler('PlannerService.getStudentEntries');

        const promises = [
            this.studentService.getStudentStudyActivities(studentId).then(mapActivities, handleFailure),
            this.studentService.getStudentAssessments(studentId).then(mapAssessments, handleFailure),
            this.libraryService.getStudentLoans(studentId).then(mapLibraryLoans, handleFailure),
            this.studentEventService.getStudentEvents(studentId).then(mapStudentEvents, handleFailure),
        ];

        return this.promise.all(promises).then(flattenAndSort);
    }
}

function mapActivities(activities) {
    return activities.map(a => new PlannerEntry({
        ref: a,
        type: getActivityType(a),
        start: a.startDateTime,
        end: a.endDateTime,
        title: a.unit.abbreviatedTitle,
        description: [a.location.buildingNumber, a.location.buildingName].filter(s => s).join(' ') || a.location.campus,
        isImportant: true
    }));
}

function mapAssessments(assessments) {
    return filterMap(assessments, a => a.dueDateTime, a => new PlannerEntry({
        ref: a,
        type: 'assessment',
        start: subMinutes(a.dueDateTime, 30),
        end: a.dueDateTime,
        title: a.task,
        description: a.unit.code,
        isImportant: true
    }));
}

function mapAcademicEntries(academicEntries) {
    return filterMap(academicEntries, a => a.level == 'Display' || a.level == 'Alert', a => new PlannerEntry({
        ref: a,
        type: 'academic',
        start: a.startDateTime,
        end: a.endDateTime,
        title: `${a.studyPeriod}: ${a.dateType}`,
        isAllDay: a.isAllDay,
        isImportant: differenceInDays(a.endDateTime, a.startDateTime) <= 1
    }));
}

function mapLibraryLoans(loans) {
    const now = DateWrapper.new();

    return loans.map(loan => {
        const recalled = loan.statusCode === 'RECALL'
        const overdue = !recalled && isAfter(now, loan.dueDateTime);

        return new PlannerEntry({
            ref: loan,
            type: overdue ? 'library-urgent' : 'library',
            start: overdue ? now : loan.dueDateTime,
            end: overdue ? now : loan.dueDateTime,
            title: `${loan.item.title} (${recalled ? 'Recall' : (overdue ? 'Overdue' : 'Due')})`,
            isAllDay: true,
            isImportant: true
        });
    });
}

function mapStudentEvents(events) {
    return events.map(e => new PlannerEntry({
        ref: e,
        type: 'student-event',
        start: e.startDateTime,
        end: e.endDateTime,
        title: e.name,
        description: e.location,
        isImportant: true
    }));
}

function mapExamEvents(examEvents, appInsights) {
    const examSessions = [];

    examEvents.forEach(event => {
        event.exams.forEach(exam => {
            exam.sessions.forEach(session => { 
                const ref = { event, exam, session };
                
                if (!session.timeZone) {
                    appInsights.trackError(`Unknown session timezone for exam ${event.id}/${exam.id}`, 'plannerService.mapExamEvents', { model: JSON.stringify(ref) });
                }
                else {
                    const dateTimeNotEqualsUtcDateTime = !isEqual(session.dateTime, session.utcDateTime);
                    examSessions.push(new PlannerEntry({
                        ref: ref,
                        type: 'exam',
                        start: session.utcDateTime,
                        end: addMinutes(session.utcDateTime, session.durationMinutes || 0),
                        title: `Exam: ${exam.name}`,
                        description: dateTimeNotEqualsUtcDateTime
                            ? `Local exam start time: ${format(session.dateTime, 'h:mm a')}`
                            : '',
                        showAlert: dateTimeNotEqualsUtcDateTime,
                        isAllDay: false,
                        isImportant: true
                    }));
                }
            });
        });
    });

    return examSessions;
}

function flattenAndSort(collections) {
    const flat = flatten(collections);

    // Sort by duration to try and push long running events to one side
    // of the planner so they don't split events. Reverse the arguments
    // to change which side of the planner the long events tend to.
    flat.sort((a, b) => (b.end - b.start) - (a.end - a.start));

    return flat;
}

class PlannerEntry {
    constructor(opt) {
        this.ref = opt.ref;
        this.type = opt.type;
        this.start = opt.start;
        this.end = opt.end;
        this.title = opt.title;
        this.description = opt.description;
        this.isAllDay = opt.isAllDay;
        this.isImportant = opt.isImportant;
        this.showAlert = opt.showAlert;
    }
}