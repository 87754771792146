import { inject } from 'core';
import { FriendlyError } from 'core-ui';

@inject('$q', 'SessionService', 'StudentService', 'ViewService')
export class SanctionsListView {
    constructor(promise, sessionService, studentService, viewService) {
        this.promise = promise;
        this.user = sessionService.current();
        this.studentService = studentService;

        this.$viewPromise = viewService.wrap(this.$init());
    }

    $init() {
        return this.studentService.getStudentSanctions(this.user.curtinId).then(sanctions => {
            if (!sanctions)
                throw new FriendlyError('Error loading sanctions.');
                
            this.sanctions = sanctions;
        });
    }
}