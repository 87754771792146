import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import dynamicModel from 'ng-dynamic-model';
import modalDialog from 'ng-modal-dialog';
import coreUiLib from 'core-ui/lib';
import authLib from 'auth/lib';
import ngTouch from 'angular-touch';

import 'content/css/site.scss';

import { directive } from 'core-ui';

// Config
import { RouteConfig } from './config/routes';
import { DialogConfig } from './config/dialogs';
import { AppConfig } from './config/app';
import { DecoratorConfig } from './config/decorators';

// Run-once items
import { AddWebApiMiddleware } from './runners/add-webapi-middleware';
import { AddApplicationInsightsTracking } from './runners/add-application-insights-tracking';
import { AddGoogleTracking } from './runners/add-google-tracking';
import { AddNavigationHooks } from './runners/add-navigation-hooks';
import { AddScrollTopOnNav } from './runners/add-scroll-top-on-nav';
import { AddCacheCleanup } from './runners/add-cache-cleanup';
import { AddExceptionNotifications } from './runners/add-exception-notifications';

// Services
import { StudentService } from './services/student-service';
import { UnitService } from './services/unit-service';
import { NavigatorService } from './services/navigator-service';
import { SupportService } from './services/support-service';
import { PlannerService } from './services/planner-service';
import { AppInsights } from './services/app-insights';
import { ViewService } from './services/view-service';
import { GoogleAnalytics } from './services/google-analytics';
import { NotificationService } from './services/notification-service';
import { NoticeService } from './services/notice-service';
import { LibraryService } from './services/library-service';
import { ReminderService } from './services/reminder-service';
import { StudentEventService } from './services/student-event-service';
import { CommunicationsService } from './services/communications-service';
import { StudentEventLogService } from './services/student-event-log-service';
import { TutorialService } from './services/tutorial-service';

// Views
import { DashboardView } from './views/dashboard';
import { PlannerCalendarView } from './views/planner/calendar';
import { PlannerActivityView } from './views/planner/activity';
import { UnitsOverviewView } from './views/units/overview';
import { UnitsDetailsView } from './views/units/details';
import { ContactsListView } from './views/contacts/list';
import { ContactsDetailsView } from './views/contacts/details';
import { AssessmentsDetailsView } from './views/assessments/details';
import { AssessmentsOverviewView } from './views/assessments/overview';
import { AssessmentsOverviewUpcomingView } from './views/assessments/overview/upcoming';
import { AssessmentsOverviewUnitView } from './views/assessments/overview/unit';
import { LibraryView } from './views/library';
import { ProgressOverviewView } from './views/progress/overview';
import { ProgressDetailsView } from './views/progress/details'; 
import { MapView } from './views/map';
import { FeedbackView } from './views/feedback';
import { SupportContactsView } from './views/support/contacts';
import { SupportFaqsView } from './views/support/faqs';
import { SanctionsListView } from './views/sanctions/list';
import { SanctionsContactView } from './views/sanctions/contact';
import { ReportProblemView } from './views/report-problem';
import { MockingView } from './views/mocking';
import { MyEventsEditView } from './views/my-events/edit';
import { MyEventsDetailsView } from './views/my-events/details';
import { NoticeView } from './views/notice';
import { CommunicationsDashboardAlertsView } from './views/communications/dashboard/alerts';
import { CommunicationsDashboardOfficialCommunicationsView } from './views/communications/dashboard/official-communications';
import { CommunicationsOfficialCommunicationView } from './views/communications/official-communication';

// Dialogs
import { DateTimePickerDialog } from './dialogs/date-time-picker';

// Directives
import { StateNavigatorDirective } from './components/state-navigator';
import { TablePlannerDirective } from './components/table-planner';
import { TimelineDirective } from './components/timeline';
import { UnitListDirective } from './components/unit-list';
import { DatePickerDirective } from './components/date-picker';
import { AssessmentListDirective } from './components/assessment-list';
import { ContactDetailsDirective } from './components/contact-details';
import { TrackLinkDirective } from './components/track-link';
import { NotificiationToastDirective } from './components/notification-toast';
import { ReminderListDirective } from './components/reminder-list';
import { NavLinkDirective } from './components/nav-link';
import { AutoCompleteDirective } from './components/auto-complete';
import { TimePickerDirective } from './components/time-picker';
import { MazeMapDirective } from './components/maze-map';
import { VersionCheckDirective } from './components/version-check';
import { UserTrayDirective } from './components/user-tray';
import { NgIncludeReplaceDirective } from './components/ng-include-replace';
import { TutorialDirective } from './components/tutorial';
import { UsabillaDirective } from './components/usabilla';

// Filters
const app = angular.module('elsie.app', [ uiRouter, coreUiLib, authLib, ngTouch, dynamicModel, modalDialog ])
    .config(RouteConfig)
    .config(DialogConfig)
    .config(AppConfig)
    .config(DecoratorConfig)
    
    .run(AddWebApiMiddleware)
    .run(AddApplicationInsightsTracking)
    .run(AddGoogleTracking)
    .run(AddNavigationHooks)
    .run(AddCacheCleanup)
    .run(AddExceptionNotifications)
    .run(AddScrollTopOnNav)

    .service('StudentService', StudentService)
    .service('UnitService', UnitService)
    .service('NavigatorService', NavigatorService)
    .service('SupportService', SupportService)
    .service('AppInsights', AppInsights)
    .service('PlannerService', PlannerService)
    .service('ViewService', ViewService)
    .service('GoogleAnalytics', GoogleAnalytics)
    .service('NotificationService', NotificationService)
    .service('NoticeService', NoticeService)
    .service('LibraryService', LibraryService)
    .service('ReminderService', ReminderService)
    .service('StudentEventService', StudentEventService)
    .service('CommunicationsService', CommunicationsService)
    .service('StudentEventLogService', StudentEventLogService)
    .service('TutorialService', TutorialService)

    .controller('DashboardView', DashboardView)
    .controller('PlannerCalendarView', PlannerCalendarView)
    .controller('PlannerActivityView', PlannerActivityView)
    .controller('UnitsOverviewView', UnitsOverviewView)
    .controller('UnitsDetailsView', UnitsDetailsView)
    .controller('ContactsListView', ContactsListView)
    .controller('ContactsDetailsView', ContactsDetailsView)
    .controller('AssessmentsOverviewView', AssessmentsOverviewView)
    .controller('AssessmentsOverviewUpcomingView', AssessmentsOverviewUpcomingView)
    .controller('AssessmentsOverviewUnitView', AssessmentsOverviewUnitView)
    .controller('AssessmentsDetailsView', AssessmentsDetailsView)    
    .controller('LibraryView', LibraryView)
    .controller('ProgressOverviewView', ProgressOverviewView)
    .controller('ProgressDetailsView', ProgressDetailsView)
    .controller('MapView', MapView)
    .controller('FeedbackView', FeedbackView)
    .controller('SupportContactsView', SupportContactsView)
    .controller('SupportFaqsView', SupportFaqsView)
    .controller('SanctionsListView', SanctionsListView)
    .controller('SanctionsContactView', SanctionsContactView)
    .controller('ReportProblemView', ReportProblemView)
    .controller('MockingView', MockingView)
    .controller('MyEventsEditView', MyEventsEditView)
    .controller('MyEventsDetailsView', MyEventsDetailsView)
    .controller('NoticeView', NoticeView)
    .controller('DateTimePickerDialog', DateTimePickerDialog)
    .controller('CommunicationsDashboardAlertsView', CommunicationsDashboardAlertsView)
    .controller('CommunicationsDashboardOfficialCommunicationsView', CommunicationsDashboardOfficialCommunicationsView)
    .controller('CommunicationsOfficialCommunicationView', CommunicationsOfficialCommunicationView)

    .directive('notificationToast', directive(NotificiationToastDirective))
    .directive('stateNavigator', directive(StateNavigatorDirective))
    .directive('tablePlanner', directive(TablePlannerDirective))
    .directive('timeline', directive(TimelineDirective))
    .directive('unitList', directive(UnitListDirective))
    .directive('datePicker', directive(DatePickerDirective))
    .directive('assessmentList', directive(AssessmentListDirective))
    .directive('contactDetails', directive(ContactDetailsDirective))
    .directive('trackLink', directive(TrackLinkDirective))
    .directive('reminderList', directive(ReminderListDirective))
    .directive('navLink', directive(NavLinkDirective))
    .directive('autoComplete', directive(AutoCompleteDirective))
    .directive('timePicker', directive(TimePickerDirective))
    .directive('mazeMap', directive(MazeMapDirective))
    .directive('versionCheck', directive(VersionCheckDirective))
    .directive('userTray', directive(UserTrayDirective))
    .directive('ngIncludeReplace', directive(NgIncludeReplaceDirective))
    .directive('tutorial', directive(TutorialDirective))
    .directive('usabilla', directive(UsabillaDirective));

export default app.name;
