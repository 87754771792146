import { inject } from 'core';

@inject('$scope', 'StudentService', 'SessionService', 'ViewService')
export class AssessmentsOverviewView {
    constructor(scope, studentService, sessionService, viewService) {
        this.scope = scope;
        this.studentService = studentService;
        this.user = sessionService.current();

        this.$viewPromise = viewService.wrap(this.$init());
    }

    $init() {
        return this.studentService.getStudentAssessments(this.user.curtinId).then(assessments => {
            this.scope.assessments = assessments.sort(dueDateSort);
        });
    }
}

function dueDateSort(a1, a2) {
    // Order dates, nulls last (so make them MAX_SAFE_INTEGER for comparison purposes)
    return (a1.dueDateTime || Number.MAX_SAFE_INTEGER) - (a2.dueDateTime || Number.MAX_SAFE_INTEGER);
}