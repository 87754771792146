import { inject } from 'core';
import { format } from 'date-fns';
import config from 'config';

const RefreshInterval = 10 * 60 * 1000; // 10 minutes

@inject('$scope', '$stateParams', '$interval', 'CommunicationsService', 'ViewService')
export class CommunicationsOfficialCommunicationView {
    constructor(scope, stateParams, interval, communicationsService, viewService) {
        this.scope = scope;
        this.interval = interval;
        this.communicationsService = communicationsService;

        this.userId = stateParams.userId;
        this.messageId = stateParams.messageId;

        this.attachmentToken = "";
        this.$viewPromise = viewService.wrap(this.$init());
    }

    $init() {
        return this.communicationsService.getOfficialCommunication(this.userId, this.messageId, true)
            .then((officialCommunication) => {
                this.officialCommunication = officialCommunication;
                if (this.officialCommunication && this.officialCommunication.files.length > 0) {

                    const initInterval = this.interval(() => this.getAttachmentToken(this.userId, this.messageId), RefreshInterval);
                    this.scope.$on('$destroy', () => this.interval.cancel(initInterval));

                    return this.getAttachmentToken(this.userId, this.messageId);
                }
            });
    }

    getFormattedDate(date) {
        return format(date, 'ddd, DD MMM YYYY hh:mm');
    }

    getAttachmentLink(file) {
        return `${config.urls.files}/official-communications/${this.officialCommunication.messageId}/attachments/${file.fileId}?token=${this.attachmentToken}&filename=${encodeURIComponent(file.fileName)}`;
    }

    getAttachmentToken(userId, messageId) {
        return this.communicationsService.getOfficialCommunicationAttachmentToken(userId, messageId)
            .then((attachmentToken) => this.attachmentToken = attachmentToken);
    }
}