import angular from 'angular';
import 'polyfills';

import { HttpConfig } from './http-config';
import { ExceptionHandlerProvider } from './exceptions';
import { WebApiService } from './web-api';
import { CacheFactory } from './cache-factory';
import { StorageServiceProvider } from './storage';

let lib = angular.module('core.lib', [])
    .config(HttpConfig)
    .provider('$exceptionHandler', ExceptionHandlerProvider)
    .service('WebApiService', WebApiService)
    .service('CacheFactory', CacheFactory)
    .provider('StorageService', StorageServiceProvider);

export default lib.name;

