export function UsabillaDirective(window, sessionService) { 
    this.window = window;
    this.session = sessionService;
}

UsabillaDirective.prototype = {
    restrict: 'A',
    dependencies: ['$window', 'SessionService'],
    link: function(scope, element, attrs) {
        const user = this.session.current();
        if (this.window.usabilla && attrs['usabilla']){
            this.window.usabilla.load('w.usabilla.com', attrs['usabilla']);
            element.attr("ub-in-page-student-id", user.curtinId);
        }
    }
};