import { inject } from 'core';

export function ContactDetailsDirective() { }

@inject('$scope')
class ContactDetailsController {
    constructor(scope) {
        this.contact = scope.contact;
    }
}

ContactDetailsDirective.prototype = {
    restrict: 'A',
    templateUrl: require('./contact-details.html'),
    scope: { 
        contact: '='
    },
    controller: ContactDetailsController,
    controllerAs: 'vm'
};
