// Taken from https://stackoverflow.com/questions/31089801/extending-error-in-javascript-with-es6-syntax-babel#answer-32749533
// but slightly modified to not require babel-transform-builtins
export class ExtendableError extends Error {
    constructor(message, name) {
        super(message);
        this.name = name;

        if (typeof(Error.captureStackTrace) === 'function') 
            Error.captureStackTrace(this, this.constructor);
        else
            this.stack = (new Error(message)).stack;
    }
}