import { inject } from 'core';
import { getFacultyIdentifier } from 'elsie/utils';

export function UnitListDirective() { }
const PassFailResultType = 'Pass/Fail';

@inject('$scope')
class UnitListController {
    constructor(scope){
        this.scope = scope;
        this.units = scope.units;
    }

    getFacultyClass(unit, index) {
        return `faculty-${getFacultyIdentifier(unit.faculty)}${(index%5)+1}`;
    }

    hasGrade(unit) {
        return unit.grade && unit.grade.length;
    }

    getUnitResultText(unit) {
        return unit.resultType === PassFailResultType 
            || unit.statusCode === unit.grade.toUpperCase()
                ? unit.statusCode
                : `${unit.statusCode} (${unit.grade})`;
    }

    getUnitMarkText(unit) {
        return unit.resultType === PassFailResultType
            ? null
            : unit.mark;
    }
}

UnitListDirective.prototype = {
    restrict: 'A',
    replace: true,
    templateUrl: require('./unit-list.html'),
    controller: UnitListController,
    controllerAs: 'vm',
    scope: { 
        units: '=',
        onUnitClicked: '&'
    }
};
