import { inject } from './annotations';

const UNHANDLED_ERROR_CODES = [ 401, 403, 500 ];

function getRequestConfig(params, cache) {
    if (typeof(params) !== 'object' && typeof(cache) === 'undefined') {
        cache = params;
        params = null;
    }

    return { 
        headers: {},
        cache: !!cache,
        params: params || null
    };
}

@inject('$q', '$http')
export class WebApiService {
    constructor(promise, http) {
        this.http = http;
        this.promise = promise;

        this.$middleware = [];
        this.$pipeline = req => this.$send(req); 
    }

    use(middleware) {
        if (typeof(middleware) !== 'function')
            throw new TypeError('WebApiService::use: middleware must be a valid function');

        this.$middleware.push(middleware);

        // Reconstruct the pipeline
        let pipeline = req => this.$send(req);

        for(let i = this.$middleware.length; i > 0; --i) {
            const next = pipeline;
            const middleware = this.$middleware[i - 1];
            pipeline = req => middleware(req, next);
        }

        this.$pipeline = pipeline;
        return this;
    }

    send(req) {
        return this.$pipeline(req);
    }

    get(url, params, cache) {
        const req = { method: 'GET', url, config: getRequestConfig(params, cache) };
        return this.$pipeline(req);
    }  

    post(url, data, params) {
        const req = { method: 'POST', url, data, config: getRequestConfig(params) };
        return this.$pipeline(req);
    }

    delete(url, params) {
        const req = { method: 'DELETE', url, config: getRequestConfig(params) };
        return this.$pipeline(req);
    }

    patch(url, data, params) {
        const req = { method: 'PATCH', url, data, config: getRequestConfig(params) };
        return this.$pipeline(req);
    }

    put(url, data, params) {
        const req = { method: 'PUT', url, data, config: getRequestConfig(params) };
        return this.$pipeline(req);
    }

    $send(req) {
        const method = req.method.toLowerCase();
        let promise = [ 'post', 'put', 'patch' ].indexOf(method) >= 0
            ? this.http[method](req.url, req.data, req.config)
            : this.http[method](req.url, req.config);

        return promise.then(res => res.data, err => {
            const isStructuredResponse = err && err.hasOwnProperty('data')
                && err.data && err.data.hasOwnProperty('status');

            return isStructuredResponse && UNHANDLED_ERROR_CODES.indexOf(err.data.status) < 0
                ? err.data
                : this.promise.reject(err);
        });
    }
};