import { inject } from 'core';
import { FriendlyError } from 'core-ui';

@inject('$state', 'ViewService', 'NoticeService')
export class NoticeView {
    constructor(state, viewService, noticeService) {
        this.state = state;
        this.noticeService = noticeService;
        this.$viewPromise = viewService.wrap(this.$init());
    }

    $init() {
        return this.noticeService.getNoticeById(this.state.params.id).then(notice => {
            this.notice = notice;
            if (!this.notice)
                throw new FriendlyError('Notice cannot be found.');
        });
    }
}