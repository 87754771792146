import { isStorageSupported } from './util';

const StorageKey = 'app-storage';

export class StorageServiceProvider {
    setBackingStorage(storage) {
        this.$backingStorage = storage;
    }

    $get() {
        return new StorageService(this.$backingStorage || window.sessionStorage);
    }
}

class StorageService {
    constructor(backingStorage) {
        this.isSupported = isStorageSupported(backingStorage);
        this.backingStorage = backingStorage;
        this.load();
    }
    
    set(key, value) {
        this.$$state[key] = value;
        this.save();
        return this;
    }

    get(key, defaultValue) {
        return this.$$state.hasOwnProperty(key)
            ? this.$$state[key]
            : defaultValue;
    }   

    unset(key) {
        var value = this.$$state[key];
        delete this.$$state[key];
        this.save();
        return value;
    }

    clear() {
        this.$$state = {};
        this.save();
    }

    has(key) {
        return this.$$state.hasOwnProperty(key);
    }

    save() {
        if (this.isSupported)
            this.backingStorage.setItem(StorageKey, JSON.stringify(this.$$state));
    }

    load() {
        var state;

        if (this.isSupported) {
            var json = this.backingStorage.getItem(StorageKey);
            if (json) state = JSON.parse(json);
        }

        this.$$state = state || {};
    }
}