// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(callback, interval, immediate) {
    let timeout;
    return (...args) => {
        let callNow = immediate && !timeout;
        clearTimeout(timeout);

        if (callNow) {
            callback(...args);
        }
        else {
            timeout = setTimeout(() => {
                timeout = null;
                callback(...args);
            }, interval);
        }
    };
};