import config from 'config';
import { uuid } from 'core';

AddWebApiMiddleware.$inject = [ 'WebApiService' ];

export function AddWebApiMiddleware(webApi) {
    const appName = config.appName;

    // Set the proper base url for API requests as well as a correlation id
    webApi.use((req, next) => {
        if (req.url.indexOf(config.urls.api) < 0)
            req.url = config.urls.api + req.url;
        req.config.headers['X-Correlation-Id'] = uuid();
        req.config.headers['X-Consumer-Id'] = appName;

        return next(req);
    });
}