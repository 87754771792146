import { inject } from 'core';

@inject('$state', '$sce')
export class MapView {
    constructor(state, sce) {
        this.sce = sce;

        this.campusId = 296; // Bentley Campus
        this.state = state;

        this.query = this.state.params.query;
    }

    getMapUrl() {
        let url = `https://use.mazemap.com/?v=1&campuses=curtinuniversity&zlevel=1&campusid=${this.campusId}`;
        if (this.query)
            url = url + `&search=${this.query}`;
        return this.sce.trustAsResourceUrl(url)
    }
}