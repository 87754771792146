
import { inject } from 'core';

@inject('$scope')
export class TimePickerController {
    constructor(scope) {
        const minuteInterval = Number(scope.minuteInterval) || 1;
        if (minuteInterval <= 0)
            throw new TypeError('time-picker: invalid minute-interval specified; must be a positive non-zero integer');

        this.scope = scope;
        this.hours = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ];
        this.minutes = [];

        for(var i = 0; i < 60; i += minuteInterval)
            this.minutes.push(pad(i));

        if (!scope.time)
            scope.time = new Date();

        scope.$watch('time', newValue => this.updateTime(newValue));
    }

    updateTime(newTime) {
        if (newTime && this.time !== newTime) {
            const hour = newTime.getHours();

            this.time = newTime;
            this.minute = pad(newTime.getMinutes());
            this.designator = hour >= 12 ? 'pm' : 'am';
            this.hour = hour % 12 || 12;
        }
    }

    updateScope() {
        this.time.setHours(this.hour % 12 + (this.designator === 'pm' ? 12 : 0));
        this.time.setMinutes(Number(this.minute));

        this.scope.time = this.time;
        this.scope.onTimeChanged && this.scope.onTimeChanged({ time: this.time });
    }
}

function pad(n) {
    return n < 10 ? '0'+n : n;
}

export function TimePickerDirective() {

}

TimePickerDirective.prototype = {
    restrict: 'EA',
    templateUrl: require('./time-picker.html'),
    controller: TimePickerController,
    controllerAs: 'vm',
    scope: {
        time: '=',
        minuteInterval: '=',
        onTimeChanged: '&?'
    }
}