export function NavLinkDirective(state) {
    this.state = state;
};

NavLinkDirective.prototype = {
    restrict: 'A',
    template: `<a ui-sref="{{::state}}" ng-click="handleClick($event)" ng-bind="::name"></a>`,
    dependencies: [ '$state' ],
    scope: { state: '=', name: '=' },
    link: function (scope, element, attrs) {
        if (!scope.state || !scope.name)
            throw new TypeError(`nav-link: missing required attribute '${!scope.state ? 'state' : 'name'}'`);

        scope.stateName = attrs['navLink'];
        scope.title = attrs['title'];

        let isActive = false;

        scope.$watch(() => this.state.includes(scope.state), newValue => {
            isActive = !!newValue;
            element[isActive ? 'addClass' : 'removeClass']('is-active');
        });

        scope.handleClick = function(event) {
            if (isActive)
                event.preventDefault();
        };
    }
};