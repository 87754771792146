import { inject } from 'core';
import { transformDateProps } from 'elsie/utils';
import config from 'config';

@inject('CacheFactory')
export class NoticeService {
    constructor(cacheFactory) {
        const cacheConfig = config.cache.noticeService;
        this.cache = cacheFactory.createApiCache(cacheConfig);
    }

    getNotices() {
        return this.cache.get(`/notices`)
            .then(notices => transformDateProps(notices, [ 'startDateTime', 'endDateTime', 'modifiedDateTime' ]));
    }

    getActiveNotices(date) {
        return this.getNotices()
            .then(notices => notices.filter(n => n.startDateTime < date && date < n.endDateTime));
    }

    getNoticeById(id) {
        return this.getNotices()
            .then(notices => notices.find(n => n.id == id));
    }
}