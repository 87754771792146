import { inject } from 'core';
import * as sw from 'offline-plugin/runtime';
import config from 'config';

const AutoUpdateTimeout = config.autoupdate;
const PostponeTimeout = 1 * 60 * 60 * 1000;

const Template = `
    <div class="notification-toast notification-toast--info ng-hide" ng-show="vm.updateAvailable">
        <span class="notification-toast__message">elsie needs updating!</span>
        <div class="notification-toast__actions" style="margin-top:-.5rem">
            <button class="button--secondary" ng-click="vm.postpone()">Remind me later</button>
            <button class="button--primary" ng-click="vm.update()">Update now</button>
        </div>
    </div>
`;

@inject('$scope', '$window', '$interval', '$timeout', 'WebApiService')
export class VersionCheckController {
    constructor(scope, $window, interval, timeout, webApi) {
        this.scope = scope;
        this.$window = $window;
        this.timeout = timeout;
        this.interval = interval;
        this.webApi = webApi;

        const onUpdateReady = () => this.updateAvailable = true;

        this.$handler = 'serviceWorker' in navigator 
            ? this.$registerSwCheck(onUpdateReady)
            : this.$registerApiCheck(onUpdateReady);
    }

    update() {
        this.updateAvailable = false;
        this.$handler.update();
    }

    postpone() {
        this.updateAvailable = false;
        this.$handler.postpone(PostponeTimeout);
    }

    $registerSwCheck(onUpdateReady) {
        sw.install({
            onUpdateReady: () => this.scope.$apply(onUpdateReady)
        });

        return {
            postpone: duration => this.timeout(onUpdateReady, duration),
            update: () => {
                const afterUpdate = () => this.$window.location.reload(true);
                sw.applyUpdate(afterUpdate, afterUpdate);
            }
        };
    }

    $registerApiCheck(onUpdateReady) {
        let currentVersion = null,
            latestVersion = null,
            checkInterval = null;

        const check = () => this.webApi.get('/version').then(res => {
            latestVersion = res.data;
            if (currentVersion === null)
                currentVersion = latestVersion;
            else if (latestVersion !== currentVersion) {
                this.interval.cancel(checkInterval);
                onUpdateReady();
            }
        }, () => {});

        // NOTE: If changing this interval, consider changing the SW
        // "autoupdate" interval in webpack.config.js
        checkInterval = this.interval(check, AutoUpdateTimeout);

        return {
            postpone: duration => this.timeout(onUpdateReady, duration),
            update: () => this.$window.location.reload(true)
        };
    }
}

export function VersionCheckDirective() { }

VersionCheckDirective.prototype = {
    restrict: 'A',
    template: Template,
    controller: VersionCheckController,
    controllerAs: 'vm'
};