import { inject } from 'core';
import config from 'config';

const CacheConfig = config.cache.studentEventLogService;

@inject('WebApiService', 'CacheFactory')
export class StudentEventLogService {
    constructor(webApiService, cacheFactory) {
        this.webApi = webApiService;
        this.cache = cacheFactory.createApiCache(CacheConfig);
    }

    getStudentEventLog(studentId) {
        return this.cache.get(this.$getStudentEventLogUrl(studentId));
    }

    saveStudentEventLog(studentId, event) {
        const url = this.$getStudentEventLogUrl(studentId);
        return this.webApi.post(url, event)
            .then(() => this.cache.remove(url));
    }

    $getStudentEventLogUrl(studentId) {
        return `/students/${encodeURIComponent(studentId)}/event-logs`;
    }
}