import { inject } from 'core';
import { FriendlyError } from 'core-ui';
import { DateWrapper, formatDistance, formatUnitShort, formatDateTime } from 'elsie/utils';
import { isSameDay, format } from 'date-fns';

@inject('$state', 'StudentEventService', 'SessionService', 'ViewService')
export class MyEventsDetailsView {
    constructor(state, studentEventService, sessionService, viewService) {
        this.state = state;
        this.eventId = state.params.id;
        this.user = sessionService.current();
        this.studentEventService = studentEventService;

        this.$viewPromise = viewService.wrap(this.$init());
    }

    $init() {
        return this.studentEventService.getStudentEventById(this.user.curtinId, this.eventId).then(event => {
            if (!event)
                throw new FriendlyError('Unknown student event.');

            this.event = event;
        });
    }

    delete() {
        this.saving = true;

        return this.studentEventService.removeStudentEvent(this.user.curtinId, this.event.id)
            .then(() => this.state.goBack())
            .finally(() => this.saving = false);
    }

    formatDuration(startDateTime, endDateTime) {
        const now = DateWrapper.now();
        const distance = formatDistance(endDateTime, startDateTime, formatUnitShort);

        return formatDateTime(startDateTime, now)
            + ` - ${isSameDay(startDateTime, endDateTime) ? format(endDateTime, 'h:mm a') : formatDateTime(endDateTime, now)}`
            + ` (${distance.join(' ')})`;
    }
}