import {
    format,
    differenceInYears, 
    differenceInMonths,
    differenceInWeeks,
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    differenceInCalendarDays,
    subYears, 
    subMonths,
    subWeeks,
    subDays,
    subHours,
    subMinutes,
    isSameYear,
    isSameDay
} from 'date-fns';

import { DateWrapper } from 'elsie/utils';
import { TimeZoneAbbreviations } from  '../config/timezones';

const SameYearDateFormat = 'ddd, D MMMM [at] h:mm a';
const SameYearShortDateFormat = 'ddd, D MMM [at] h:mm a';
const DifferentYearDateFormat = 'ddd, D MMM YYYY [at] h:mm a';

const TimeUnitFunctions = [
    [ 'year', differenceInYears, subYears ],
    [ 'month', differenceInMonths, subMonths ],
    [ 'week', differenceInWeeks, subWeeks ],
    [ 'day', differenceInDays, subDays ],
    [ 'hour', differenceInHours, subHours ],
    [ 'minute', differenceInMinutes, subMinutes ]
];

const ShortUnitFormats = {
    'year': 'yr',
    'month': 'mon',
    'week': 'wk',
    'day': 'd',
    'hour': 'hr',
    'minute': 'min'
};

export function formatDateTime(dateTime, now) {
    const diff = differenceInCalendarDays(dateTime, now);

    switch (diff) {
        case 0: return `Today at ${format(dateTime, 'h:mm a')}`;
        case 1: return `Tomorrow at ${format(dateTime, 'h:mm a')}`;
        case -1: return `Yesterday at ${format(dateTime, 'h:mm a')}`;
        default: return format(dateTime, isSameYear(dateTime, now) ? SameYearDateFormat : DifferentYearDateFormat);
    }
}

export function formatDateTimeRange(startDateTime, endDateTime) {    
    return format(startDateTime, isSameYear(startDateTime, endDateTime) ? SameYearShortDateFormat : DifferentYearDateFormat)
        + ` - ${isSameDay(startDateTime, endDateTime) 
            ? format(endDateTime, 'h:mm a') 
            : format(endDateTime, isSameYear(startDateTime, endDateTime) ? SameYearShortDateFormat : DifferentYearDateFormat)}`;
}

export function getTimezoneOffset(date) {
    const offset = date.getTimezoneOffset();
    const hours = Math.abs(offset / 60) | 0;
    const minutes = Math.abs(offset % 60);
    const pad = n => n > 10 ? n : '0'+n;

    return `${offset < 0 ? '+' : '-'}${pad(hours)}:${pad(minutes)}`;
}

export function getTimezoneName(date) {
    const enUsTimePattern = /^\d{1,2}(?::\d{1,2}){2}\s+[^\s]+\s+(.+)$/;
    const enUsMatch = enUsTimePattern.exec(date.toLocaleTimeString('en-US', { timeZoneName: 'long' }));

    if (enUsMatch != null && TimeZoneAbbreviations.hasOwnProperty(enUsMatch[1]))
        return TimeZoneAbbreviations[enUsMatch[1]];

    const dateStringPattern = /\(([^)]+)\)\s*$/;
    const dateStringMatch = dateStringPattern.exec(date.toString());

    return dateStringMatch != null
        ? TimeZoneAbbreviations[dateStringMatch[1]] || dateStringMatch[1]
        : 'Local Time';
}

export function getTimezone(date) {
    const shortTz = getTimezoneOffset(date).replace(/^([+-])?0|:00$/g, (m, g) => g||'');
    return `${getTimezoneName(date)} (GMT${shortTz})`;
}

export function formatDistance(date1, date2, formatUnitFn) {
    const components = [];

    let start = date1 > date2 ? date2 : date1;
    let end = date1 > date2 ? date1 : date2;
    
    for(let i = 0, j = TimeUnitFunctions.length; i < j && components.length < 2; ++i) {
        const unit = TimeUnitFunctions[i];
        const diff = unit[1](end, start);
        
        if (diff > 0) {
            components.push(formatUnitFn(unit[0], diff));
            end = unit[2](end, diff);
        }
        else if (unit[0] !== 'week' && components.length > 0) {
            // Only show consecutive units (except for 'week')
            break;
        }
    }

    return components;
}

export function formatUnitShort(unit, amount) {
    return `${amount} ${ShortUnitFormats[unit]}${amount === 1 ? '' : 's'}`;
}

export function formatUnitLong(unit, amount) {
    return `${amount} ${unit}${amount === 1 ? '' : 's'}`;
}

export function formatDueDate(dueDate, defaultValue) {
    if (!dueDate)
        return defaultValue || 'Check Blackboard for due date';

    const now = DateWrapper.new();
    const prefix = dueDate > now ? 'in ' : '';
    const suffix = dueDate > now ? '' : ' ago';

    const formatted = formatDistance(dueDate, now, formatUnitLong);
    const humanized = formatted.length > 0 ? `${prefix}${formatted.join(', ')}${suffix}` : 'now';

    return format(dueDate, `ddd D MMM [at] H:mm [(${humanized})]`);
}
