import config from 'config';
import { inject } from 'core';

const DefaultState = config.navigation.defaultState;

@inject('$scope', '$timeout', '$window', '$sce', '$state', '$element', 'NavigatorService', 'SessionService')
export class LibraryView {
    constructor(scope, timeout, window, sce, state, element, navigatorService, sessionService) {
        this.scope = scope;
        this.state = state;
        this.window = window;
        this.navigatorService = navigatorService;
        this.pageTitle = 'Library';

        const user = sessionService.current();
        this.librarySource = sce.trustAsResourceUrl(`https://auth.library.curtin.edu.au/elsie?access_token=${encodeURIComponent(user.accessToken)}`);

        this.registerMessageEventHandler();
        timeout(() => this.libraryFrame = element[0].querySelector('iframe'));
    }

    registerMessageEventHandler() {
        const backHandler = event => this.handleMessageEvent(event);
        this.window.addEventListener('message', backHandler);

        this.scope.$on('$destroy', () => {
            this.window.removeEventListener('message', backHandler);
        });
    }

    handleMessageEvent(event) {
        if (event.data && event.data.name) {
            if (event.data.name === 'navigateBack') {
                const previous = this.navigatorService.getLastState() || DefaultState;
                this.state.go(previous.state.name, previous.params, { inherit: false });
            }
            else if (event.data.name === 'updatePageTitle') {
                this.scope.$apply(() => this.pageTitle = event.data.title); 
            }
        }
    }
    
    onBack(event) {
        this.libraryFrame.contentWindow.postMessage({ name: 'navigateBack' }, 'https://auth.library.curtin.edu.au');
        event.preventDefault();
    }
}
