// Can't @inject functions
DialogConfig.$inject = [ '$modalDialogProvider' ];

export function DialogConfig(modalDialogProvider) {
    modalDialogProvider.register('date-time-picker', {
        templateUrl: require('../dialogs/date-time-picker.html'),
        controller: 'DateTimePickerDialog',
        controllerAs: 'vm',
        cancellable: true
    });
}