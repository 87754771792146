import { inject } from 'core';
import { getFacultyIdentifier, groupBy, isActiveUnit } from 'elsie/utils';

@inject('$stateParams', 'SessionService', 'StudentService', 'ViewService')
export class ContactsListView {
    constructor(stateParams, sessionService, studentService, viewService) {
        this.user = sessionService.current();
        this.studentService = studentService;
        this.facultyId = stateParams.facultyId;

        this.$viewPromise = viewService.wrap(this.$init());
    }

    $init() {
        return this.studentService.getStudentUnitContacts(this.user.curtinId, isActiveUnit).then(unitContacts => {
            const groups = groupBy(unitContacts, c => getFacultyIdentifier(c.unit.faculty), 'id');
            const facultyGroups = this.facultyId ? groups.filter(f => f.id == this.facultyId) : groups;
            this.unitContacts = unitContacts;
            this.faculties = facultyGroups.filter(faculty => faculty.some(contacts => contacts.length));
            this.title = this.facultyId && this.faculties.length ? this.faculties[0][0].unit.faculty : 'All unit contacts';
        });
    }
}