AddWebApiMiddleware.$inject = [ '$q', '$state', 'WebApiService', 'SessionService' ];

export function AddWebApiMiddleware(promise, state, webApi, sessionService) {
    let refreshPromise = null,
        transitionPromise = null;

    // Attach auth middleware
    webApi.use(refreshTokenMiddleware);
    webApi.use(attachTokenMiddleware);

    function attachTokenMiddleware(req, next) {
        const user = sessionService.current();
        if (user != null)
            req.config.headers['Authorization'] = 'Bearer ' + user.accessToken;
        return next(req);
    }
    
    function refreshTokenMiddleware(req, next) {
        // Add 401 handling, which is a two step process.
        // The first step attempts to seamlessly refresh the session, 
        //  while the second redirects the user back to the login page with an 'expiry' notice.
        return next(req).catch(err => {
            if (err.name !== 'HttpError' || err.status !== 401)
                return promise.reject(err); // Don't know how to handle this.

            const user = sessionService.current();
            
            if (user == null)
                return startLoginTransition(err);

            if (refreshPromise === null) {
                refreshPromise = sessionService.refresh(user)
                    .finally(() => refreshPromise = null);
            }

            return refreshPromise.then(
                res => res.status === 201 ? webApi.send(req.originalReq || req) : startLoginTransition(err),
                () => startLoginTransition(err)
            );
        });
    }
    
    function startLoginTransition(err) {
        if (transitionPromise === null) {
            sessionService.clear();

            const returnTo = {
                state: state.current,
                params: angular.merge({}, state.params)
            };

            silenceTransitionError(state.transition);
            transitionPromise = state.transitionTo('login', { expired: true, returnTo }, { location: 'replace' })
                .finally(() => transitionPromise = null);
        }

        return promise.reject(err);
    }
}

// Newer versions of angular (>=1.5.9) hit the $exceptionHandler when a
// possibly unhandled promise rejection is detected. 
//
// This function attaches a noop rejection handler to a 'transition' (promise) 
// to silence any transition errors (i.e. "transition superseded").
//
// This is needed as the interceptor may be hit during a state's "resolve"
// phase (i.e. it is still 'in transition') and would thus supersede the 
// current transition when calling state.transitionTo.
function silenceTransitionError(transition) {
    if (transition && typeof (transition.catch) === 'function')
        transition.catch(noop);
}