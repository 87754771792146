import { inject } from 'core';
import config from 'config';

const Endpoints = {
    notificationsOverview: '/students/{studentId}/communications/notifications/overview',
    alerts: '/students/{studentId}/communications/alerts',
    officialComms: '/students/{studentId}/communications/official-communications',
    officialCommunication: '/students/{studentId}/communications/official-communications/{messageId}',
    officialCommunicationAttachmentToken: '/students/{studentId}/communications/official-communications/{messageId}/attachment-token'
};

@inject('CacheFactory')
export class CommunicationsService {
    constructor(cacheFactory) {
        const cacheConfig = config.cache.communicationsService;
        this.cache = cacheFactory.createApiCache(cacheConfig);
    }

    getStudentNotificationsOverview(studentId, bypassCache) {
        const key = getCacheKey(Endpoints.notificationsOverview, studentId);
        const cached = this.cache.getCached(key);
        const options = bypassCache ? { ttl: 0 } : undefined;

        return this.cache.get(getCacheKey(Endpoints.notificationsOverview, studentId), options).then(overview => {
            if (angular.equals(cached, overview))
                return cached;

            // Clear out the other cached items if the overview changed
            this.cache.remove(getCacheKey(Endpoints.alerts, studentId));
            this.cache.remove(getCacheKey(Endpoints.officialComms, studentId));

            return overview;
        });
    }

    getStudentAlerts(studentId) {
        return this.cache.get(getCacheKey(Endpoints.alerts, studentId));
    }

    getOfficialCommunicationsSummary(studentId) {
        return this.cache.get(getCacheKey(Endpoints.officialComms, studentId));
    }

    getOfficialCommunication(studentId, messageId, bypassCache) {
        const options = bypassCache ? { ttl: 0 } : undefined;

        return this.cache.get(getCacheKey(Endpoints.officialCommunication, studentId, messageId), options);
    }

    getOfficialCommunicationAttachmentToken(studentId, messageId) {
        return this.cache.get(getCacheKey(Endpoints.officialCommunicationAttachmentToken, studentId, messageId), {ttl: 0});
    }
}

function getCacheKey(endpoint, studentId) {
    return endpoint.replace('{studentId}', encodeURIComponent(studentId));
}

function getCacheKey(endpoint, studentId, messageId) {
    return endpoint.replace('{studentId}', encodeURIComponent(studentId)).replace('{messageId}', messageId);
}