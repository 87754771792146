import { inject } from 'core';
import { getTimezone, DateWrapper } from 'elsie/utils';

import {
    format,
    parse,
    addMonths, 
    subMonths,
    isBefore, 
    isAfter,
    startOfDay,
    endOfDay,
    areIntervalsOverlapping
} from 'date-fns';

@inject('$state','SessionService', 'PlannerService', 'ViewService')
export class PlannerCalendarView {
    constructor(state, sessionService, plannerService, viewService) {
        this.state = state;
        this.user = sessionService.current();
        this.plannerService = plannerService;
        
        this.$viewPromise = viewService.wrap(this.$init());
    }

    $init() {
        this.selectedDate = this.state.params.date 
            ? parse(this.state.params.date, 'YYYY-MM-DD', DateWrapper.new())
            : DateWrapper.new();
        
        this.timezone = getTimezone(this.selectedDate);
    
        return this.plannerService.getStudentEntries(this.user.curtinId).then(entries => {
            this.entries = entries;
            this.interval = buildInterval(entries);
        });
    }
    
    shouldHighlightDay(date) {
        const dayInterval = { start: startOfDay(date), end: endOfDay(date) };
        return this.entries.some(e => e.isImportant && areIntervalsOverlapping(dayInterval, e));
    }

    onDateChanged(date) {
        this.timezone = getTimezone(date);
        this.state.transitionTo('planner.calendar', { date: format(date, 'YYYY-MM-DD') }, {
            location: 'replace',
            reload: false
        });
    }
}

function buildInterval(entries) {
    const now = DateWrapper.new();

    const interval = { 
        start: subMonths(now, 1),
        end: addMonths(now, 1)
    };

    for(let i = 0, j = entries.length; i < j; ++i) {
        if (isBefore(entries[i].start, interval.start))
            interval.start = entries[i].start;

        if (isAfter(entries[i].end, interval.end))
            interval.end = entries[i].end;
    }

    return interval;
}