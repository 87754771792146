import { inject } from './annotations';
import { isStorageSupported } from './util';

const StoragePrefix = '__cache_';

@inject('$q')
export class CacheFactory {
    constructor(q) {
        this.$$q = q;
        this.$$existing = {};
        // Keep track of creation options in a localStorage cache so that they can be cleared correctly.
        this.$$options = new Cache(this.$$q, '$$CacheOptions', { storage: 'local' });
    }

    get(name) {
        return this.$$existing[name] || null;
    }

    create(name, options) {
        const cache = new Cache(this.$$q, name, options);
        this.$$existing[name] = cache;
        this.$$options.put(name, options);
        return cache;
    }

    clearAll() {
        return this.$$q.all(this.$$options.keys().map(key => {
            // Try and get the existing in-memory cache if it exists, otherwise create a temp one
            const cache = this.get(key) 
                || this.$$options.get(key).then(o => new Cache(this.$$q, key, o));

            return this.$$q.when(cache).then(c => c.clear());
        }));
    }
}

class Cache {
    constructor(q, name, options) {
        this.name = name;

        this.$$q = q;
        this.$$defaults = options;
        this.$$subscribers = null;
        this.$$pending = {};

        switch(getOption('storage', options, { storage: 'memory' })) {
        case 'local':
            this.$$storage = isStorageSupported(window.localStorage) && window.localStorage;
            break;
        case 'session':
            this.$$storage = isStorageSupported(window.sessionStorage) && window.sessionStorage;
            break;
        }

        this.$load();
    }

    subscribe(fn) {
        if (typeof(fn) !== 'function')
            throw new TypeError('fn must be a valid function');

        if (!this.$$subscribers)
            this.$$subscribers = [];

        this.$$subscribers.push(fn);
        return () => this.unsubscribe(fn);
    }

    unsubscribe(fn) {
        if (this.$$subscribers) {
            const idx = this.$$subscribers.indexOf(fn);
            if (idx >= 0)
                this.$$subscribers.splice(idx, 1);
        }
    }

    clear() {
        // Fire subscribers to let them know all the cache items are being invalidated.
        this.keys().forEach(key => this.$emit(key));

        this.$$cache = {};
        this.$save();
    }

    keys() {
        return Object.keys(this.$$cache);
    }

    get(key, options) {
        const cached = this.getCached(key, options);
        if (typeof(cached) !== 'undefined')
            return this.$$q.when(cached);

        if (this.$$pending.hasOwnProperty(key))
            return this.$$pending[key];

        // Ensure the cached item is removed
        this.remove(key);
        const fetch = getOption('fetch', options, this.$$defaults);

        if (typeof(fetch) === 'undefined')
            return this.$$q.when(undefined);

        return this.$$pending[key] = this.$$q.when(fetch(key, this))
            .finally(() => delete this.$$pending[key]);
    }

    put(key, value) {
        this.$$cache[key] = {
            obj: value,
            added: Date.now()
        };

        this.$save();
        this.$emit(key, value);
        return value;
    }

    remove(key) {
        if (this.$$cache.hasOwnProperty(key))
            delete this.$$cache[key];
        this.$save();
        this.$emit(key);
    }

    getCached(key, options) {
        const item = this.$$cache[key];
        const ttl = getOption('ttl', options, this.$$defaults);

        return item && (ttl == null || (Date.now() - item.added) <= ttl)
            ? item.obj
            : undefined;
    }

    $emit(key, value) {
        if (this.$$subscribers !== null) {
            // Invoke all the subscribers
            const subscribers = this.$$subscribers.slice();

            for(let i = 0, j = subscribers.length; i < j; ++i) {
                subscribers[i](key, value, this);
            }
        }
    }

    $save() {
        if (this.$$storage) {
            try {
                this.$$storage.setItem(StoragePrefix + this.name, JSON.stringify(this.$$cache));
            }
            catch(err) {
                // Don't want failed cache persistence to break the whole app
            }
        }
    }

    $load() {
        let cache;

        if (this.$$storage) {
            const json = this.$$storage.getItem(StoragePrefix + this.name);
            cache = json && JSON.parse(json);
        }

        this.$$cache = cache || {};
    }
}

function getOption(name, overrides, defaults) {
    return overrides && overrides.hasOwnProperty(name)
        ? overrides[name]
        : defaults && defaults[name];
}