import { inject } from 'core';
import { format } from 'date-fns';

@inject('$state', 'SessionService', 'CommunicationsService', 'ViewService')
export class CommunicationsDashboardOfficialCommunicationsView {
    constructor(state, sessionService, communicationsService, viewService) {
        this.state = state;
        this.communicationsService = communicationsService;
        this.user = sessionService.current();

        this.$viewPromise = viewService.wrap(this.$init());
    }

    $init() {
        return this.communicationsService.getOfficialCommunicationsSummary(this.user.curtinId)
            .then((summary) => {
                this.summary = summary;
            });
    }

    getFormattedDate(date) {
        return format(date, 'DD MMM YYYY');
    }
}