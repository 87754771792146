AddCacheCleanup.$inject = [ '$rootScope', 'CacheFactory' ];

export function AddCacheCleanup(rootScope, cacheFactory) {
    const cleanup = () => cacheFactory.clearAll();

    // Probably only need it on *Ended, but 
    // if for some reason that doesn't fire (i.e. manual intervention)
    // the next login will nuke it.
    rootScope.$on('$userSessionStarted', cleanup);
    rootScope.$on('$userSessionEnded', cleanup);
}