import { inject } from 'core';

@inject('$q', '$state', 'SessionService', 'StudentService', 'ViewService')
export class SanctionsContactView {
    constructor(promise, state, sessionService, studentService, viewService) {
        this.promise = promise;
        this.state = state;
        this.user = sessionService.current();
        this.studentService = studentService;

        this.$viewPromise = viewService.wrap(this.$init());
    }

    $init() {
        return this.$getSanctionDetails().then(sanction => {
            if (!sanction)
                throw new FriendlyError('Unknown sanction.');
            
            this.contact = {
                title: 'Sanction Contact',
                description: sanction.contactNote,
                phone: sanction.contactPhone,
                emailAddress: sanction.contactEmail,
            };
        });
    }

    $getSanctionDetails() {
        const params = this.state.params;

        if (params.sanction) 
            return this.promise.when(params.sanction);

        return this.studentService.getStudentSanctions(this.user.curtinId)
            .then(sanctions => sanctions && sanctions.find(obj => obj.reasonCode == params.reasonCode));
    }
}