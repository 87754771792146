import { inject } from 'core';
import { toMap } from 'elsie/utils';

@inject('$q', '$state', 'SessionService', 'StudentService', 'NotificationService', 'ViewService')
export class ProgressDetailsView {
    constructor(promise, state, sessionService, studentService, notificationService, viewService) {
        this.promise = promise;
        this.state = state;            
        this.user = sessionService.current();
        this.studentService = studentService;
        this.notificationService = notificationService;

        this.$viewPromise = viewService.wrap(this.$init());
    }

    $init() {
        const { courseCode, year, studyPeriodCode } = this.state.params;
        const filter = u => u.year == year && u.studyPeriodCode == studyPeriodCode;

        const promises = [
            this.studentService.getStudentCourseProgression(this.user.curtinId),
            this.studentService.getAllStudentUnits(this.user.curtinId)
        ];

        return this.promise.all(promises).then(([progression, allUnits]) => {
            const course = progression && progression.find(p => p.code === courseCode);
            const components = course && course.studyComponents.filter(filter) || [];
            const units = toMap(allUnits.filter(filter), u => u.availabilityId);
            
             // Use studyComponents as the reference, but prefer a unit if there's a matching one
            this.units = components.filter(filter).map(component => {
                const match = units[component.availabilityId];
                return Object.assign({ hasDetails: !!match }, match || component);
            });

            this.title = this.units.length 
                ? (year !== 'ALL' ? `${year}, ` : '') + this.units[0].studyPeriod
                : `${year} Progress`;
        });
    }

    onUnitClicked(unit, event) {
        if (!unit.hasDetails) {
            event.preventDefault();
            this.notificationService.warning(`We don't have any further information about this unit to display`, { timeout: 2000 });
        }
    }
}