import { inject, debounce } from 'core';

export function MazeMapDirective() { };

const AnimationSpeed = 1.5;
const ZoomLevel = 19;

@inject('$scope', '$document', '$element')
export class MazeMapController {
    constructor(scope, document, element) {
        this.scope = scope;
        this.document = document[0];
        this.element = element[0];

        this.$init();
    }

    $init() {
        this.change = debounce(() => this.search(this.text), 100);
        this.show = false;
        
        this.scope.$watch('query', query => query && this.search(query));

        let searchElement = this.element.querySelector('.map-search');
        const documentClickHandler = event => {
            if (!searchElement.contains(event.target)) {
                this.scope.$apply(() => this.show = false);
                this.element.querySelector('input').blur();
            }
        };

        this.$initMap(this.scope.campusId);
        let removeScrollingFix = this.$fixScrollingInIos();

        this.document.addEventListener('click', documentClickHandler);
        this.scope.$on('$destroy', () => {
            this.document.removeEventListener('click', documentClickHandler)
            navigator.geolocation.clearWatch(this.geolocationWatch);
            removeScrollingFix();
        });
    }

    $initMap(campusId) {
        const mapOptions = {
            container: 'map-container',
            campuses: campusId,
            center: this.scope.center,
            zoom: this.scope.zoom,
            speed: AnimationSpeed
        }

        this.map = new Mazemap.Map(mapOptions);
        this.map.addControl(new Mazemap.mapboxgl.NavigationControl());

        this.map.on('load', () => { 
            this.map.searchObj = new Mazemap.Search.SearchController({
                campusid: campusId,
                rows: 5,
                withpois: false,
                withbuilding: true,
                withtype: false,
                withcampus: false,
                resultsFormat: 'geojson'
            });

            this.geolocationWatch = navigator.geolocation.watchPosition(p => {
                const lngLat = {
                    lat: p.coords.latitude, 
                    lng: p.coords.longitude
                };

                if (!this.locationController) {
                    this.$initGeolocation(lngLat, p.coords.accuracy);
                }
                else {
                    this.locationController.updateLocationData({
                        lngLat,
                        accuracy: p.coords.accuracy
                    });
                }
            });
        });
    }

    // Preventing touch move event from other elements in the view except the map.
    // https://github.com/mapbox/mapbox-gl-js/issues/6095
    $fixScrollingInIos() {
        let touchingMap = false;
        let canvasElement = this.element.querySelector('canvas');

        canvasElement.addEventListener('touchstart', event => {
            touchingMap = true;
        });

        canvasElement.addEventListener('touchend', event => {
            if(event.touches.length == 0){
                touchingMap = false;
            }
        });

        const documentTouchHandler = event => {
            if (touchingMap && !canvasElement.contains(event.target)) {
                event.preventDefault();
            }
        }

        this.document.addEventListener('touchmove', documentTouchHandler);
        return () => this.document.removeEventListener('touchMove', documentTouchHandler);
    }

    $initGeolocation(lngLat, accuracy) {
        const locationMarker = new Mazemap.BlueDot({
                accuracyCircle: true
            })
            .setLngLat(lngLat)
            .setAccuracy(accuracy)
            .addTo(this.map);

        this.locationController = new Mazemap.LocationController({
            blueDot: locationMarker,
            map: this.map
        }); 

        this.locationController.setState('active');
    }

    search(text) {
        const map = this.map;
        this.results = [];
        if (text == '')
            return;

        map.searchObj && map.searchObj.search(text).then(data => {
            if (data.results) {
                this.results = data.results.features.map(feature => this.getBuildingData(feature))
            }
            this.scope.$apply(() => this.show = this.results.length > 0);
        });
    }

    select(record) {
        this.text = record.name;
        this.show = false;

        Mazemap.Data.getBuilding(record.id).then(poi => {
            const lngLat = Mazemap.Util.getPoiLngLat(poi);

            if (this.marker) {
                this.marker.setLngLat(lngLat);
            }
            else {
                this.marker = this.addNewMarker(lngLat);
            }
            this.map.flyTo({ center: lngLat, speed: AnimationSpeed, zoom: ZoomLevel });
        });
    }

    getBuildingData(feature) {
        return {
            id: feature.properties.id,
            name: feature.properties.title.replace(/<\/?[^>]+(>|$)/g, "")
        }
    }

    addNewMarker(lngLat) {
        return new Mazemap.MazeMarker({
            color: '#1c8e8c',
            innerCircle: true,
            innerCircleColor: '#FFF',
            size: 34,
            innerCircleScale: 0.5,
        })
        .setLngLat(lngLat)
        .addTo(this.map);
    }
}

MazeMapDirective.prototype = {
    templateUrl: require('./maze-map.html'),
    controller: MazeMapController,
    controllerAs: 'vm',
    scope: {
        zoom: '=',
        center: '=',
        campusId: '='
    }
}
