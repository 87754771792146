import { inject } from 'core';
import { DateWrapper, formatDateTime } from 'elsie/utils';
import { DialogResult } from 'ng-modal-dialog';
import { isSameDay, format } from 'date-fns';

const FifteenMinutes = 15 * 60 * 1000;

@inject('$q', '$state', '$modalDialog', 'StudentEventService', 'SessionService', 'ModelBuilder', 'ViewService')
export class MyEventsEditView {
    constructor(promise, state, modalDialog, studentEventService, sessionService, modelBuilder, viewService) {
        this.user = sessionService.current();
        this.modalDialog = modalDialog;
        this.promise = promise;
        this.studentEventService = studentEventService;
        this.modelBuilder = modelBuilder;
        this.state = state;

        if (state.params.id)
            this.event = { id: state.params.id };

        this.$viewPromise = viewService.wrap(this.$init());
    }

    $init() {
        const eventPromise = this.event && this.event.id
            ? this.studentEventService.getStudentEventById(this.user.curtinId, this.event.id)
            : this.promise.when(createNewEvent());

        return eventPromise.then(event => {
            this.event = event || {};
            this.model = this.modelBuilder.build(this.event);

            this.model.field('endDateTime').addValidator((field, addError) => {
                const startDateTime = this.model.field('startDateTime').value();
                const endDateTime = field.value();

                if (startDateTime && endDateTime) {
                    if (startDateTime >= endDateTime)
                        return addError('Event cannot end before it starts');
                    if ((endDateTime - startDateTime) < FifteenMinutes)
                        return addError('Events must be at least 15 minutes long');
                }

                return true;
            });
        });
    }

    save() {
        this.saving = true;

        return this.model.validate().then(valid => {
            if (valid) {
                const state = this.model.getState();
                return this.studentEventService.saveStudentEvent(this.user.curtinId, state)
                    .then(() => this.state.goBack());
            }
        }).finally(() => this.saving = false);
    }

    chooseDateTime(fieldName) {
        const field = this.model.field(fieldName);

        this.modalDialog.show('date-time-picker', { date: field.value() }, (result, dateTime) => {
            if (result === DialogResult.Success)
                field.setValue(dateTime);
        });
    }

    formatStartDateTime() {
        return formatDateTime(this.model.get('startDateTime'), DateWrapper.new());
    }

    formatEndDateTime() {
        const startDateTime = this.model.get('startDateTime');
        const endDateTime = this.model.get('endDateTime');

        return isSameDay(startDateTime, endDateTime)
            ? 'at ' + format(endDateTime, 'h:mm a')
            : formatDateTime(endDateTime, DateWrapper.new());
    }
}

function createNewEvent() {
    const now = DateWrapper.new();

    return {
        startDateTime: now,
        endDateTime: new Date(now.getTime() + 1800000)
    };
}