export function TrackLinkDirective(googleAnalytics) {
    this.googleAnalytics = googleAnalytics;
};

TrackLinkDirective.prototype = {
    restrict: 'A',
    dependencies: [ 'GoogleAnalytics' ],
    link: function(scope, $element, attrs) {
        if (!attrs['trackLink'] && !attrs['href'] && !attrs['ngHref'])
            throw new TypeError('track-link: Target needs to be provided either via directive, href or ng-href');

        $element.bind('click', () => {
            const target = attrs['trackLink']
                ? attrs['trackLink']
                : attrs['href'];

            this.googleAnalytics.trackPageView(target, attrs['title'])
        });
    }
};