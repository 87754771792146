export class NavigatorService {
    constructor() {
        this.$history = [];
    }

    getLastState() {
        const last = this.$peekState();
        return last ? last.from : null;
    }

    $peekState() {
        return this.$history.length ? this.$history[this.$history.length - 1] : null;
    }

    $replaceState(state) {
        if (this.$history.length > 0) {
            const existing = this.$history[this.$history.length - 1];
            existing.to = state.to;
        }
    }

    $pushState(state) {
        this.$history.push(state);
    }   

    $popState() {
        this.$history.pop();
    }
}