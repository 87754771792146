import config from 'config';
import { inject } from 'core';

const DefaultState = config.navigation.defaultState;

@inject('$q', '$state', 'NavigatorService')
export class ViewService {
    constructor(promise, state, navigatorService) {
        this.promise = promise;
        this.state = state;
        this.navigatorService = navigatorService;
    }

    wrap(promise) {
        return this.promise.when(promise).catch(err => {
            const previous = this.navigatorService.getLastState() || DefaultState;

            this.state.go(previous.state.name, previous.params, {
                location: 'replace',
                inherit: false
            });

            // Bubble the exception to hit the next exception handler
            return this.promise.reject(err);
        });
    }
}