AddNavigationHooks.$inject = [ '$transitions', 'NavigatorService' ];

export function AddNavigationHooks(transitionService, navigatorService) {
    transitionService.onStart({}, transition => {
        // Create a copy of the current state parameters as they'll disappear before onSuccess fires
        transition.$$previousParams = Object.assign({}, transition.router.stateService.params);
    });

    transitionService.onSuccess({}, transition => {
        const from = {
            state: transition.from(),
            params: transition.$$previousParams
        };

        const to = {
            state: transition.to(),
            params: transition.params()
        };

        if (!to.state.abstract && from.params) {
            // Determine what to do
            const last = navigatorService.$peekState();

            if (isReverseTransition(last, from, to))
                navigatorService.$popState();
            else if (from.state.name) {
                const opts = transition.options();
                const replace = opts.location === 'replace';

                navigatorService[replace ? '$replaceState' : '$pushState']({ 
                    from: from,
                    to: to
                });
            }
        }
    });

    function isReverseTransition(history, fromState, toState) {
        // Check if the current transition is a mirror of a history item
        return history !== null 
            && history.from.state.name === toState.state.name
            && history.to.state.name === fromState.state.name
            && angular.equals(history.from.params, toState.params)
            && angular.equals(history.to.params, fromState.params);
    }
};

