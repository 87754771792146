const { reduce, forEach, push } = Array.prototype;
const IdentityFn = x => x;

export function transformDateProps(collection, propNames) {
    collection && forEach.call(collection, item => {
        if (item && typeof(item) === 'object') {
            propNames.forEach(name => {
                if (typeof(item[name]) === 'string')
                    item[name] = new Date(item[name]);
            });
        }
    });

    return collection;
}

export function groupBy(collection, keySelector, keyProperty = 'key') {
    const groupMap = { };
    const groups = [];

    forEach.call(collection, item => {
        const key = keySelector(item);
        let group = groupMap[key];
        
        if (!group) {
            group = groupMap[key] = [];
            group[keyProperty] = key;
            groups.push(group);
        }

        group.push(item);
    });

    return groups;
}

export function findMin(collection, valueSelector, initial) {
    valueSelector = valueSelector || IdentityFn;

    return reduce.call(collection, (min, obj) => {
        const val = valueSelector(obj);
        return (typeof(min) === 'undefined' || val < min) ? val : min;
    }, initial);
}

export function filterMap(collection, filterFn, mapFn) {
    return reduce.call(collection, (mapped, item) => {
        if (filterFn(item))
            mapped.push(mapFn(item));
        return mapped;
    }, []);
}

export function flatten(collections) {
    return reduce.call(collections, (acc, arr) => {
        arr && push.apply(acc, arr);
        return acc;
    }, []);
}

export function filterFlatten(collections, filterFn) {
    return reduce.call(collections, (acc, arr) => {
        arr && forEach.call(arr, o => filterFn(o) && acc.push(o));
        return acc;
    }, []);
}

export function uniqueBy(collection, props) {
    return reduce.call(collection, (unique, item) => {
        if (!unique.some(o => props.every(key => o[key] === item[key]))) {
            const obj = props.reduce((o, p) => (o[p] = item[p], o), {});
            unique.push(obj);
        }

        return unique;
    }, []);
}

export function unique(collection) {
    const keys = collection.length > 0 ? Object.keys(collection[0]) : [];

    return reduce.call(collection, (unique, item) => {
        if (!unique.some(o => keys.every(key => o[key] === item[key])))
            unique.push(item);
        return unique;
    }, []);
}

export function toMap(collection, keySelector, valueSelector) {
    valueSelector = valueSelector || IdentityFn;

    return reduce.call(collection, (map, obj) => {
        map[keySelector(obj)] = valueSelector(obj);
        return map;
    }, {});
}

export function toLookup(collection, keySelector, valueSelector) {
    valueSelector = valueSelector || IdentityFn;

    return reduce.call(collection, (lookup, obj) => {
        const key = keySelector(obj);
        if (!lookup.hasOwnProperty(key))
            lookup[key] = [];

        lookup[key].push(valueSelector(obj));
        return lookup;
    }, {});
}